angular.module('genesisApp')
  .controller('AdminNewsletterEditController', function ($scope, $timeout, $rootScope, Newsletter, $stateParams, $location, NEWSLETTER_TYPES, $uibModal, Upload, OptionsService, Attachment, $state) {
    $scope.item = $stateParams.id ? (new Newsletter).find($stateParams.id) : (new Newsletter);

    $scope.template = {
      id: null,
      article_body: "",
      top_content: "",
      bottom_content: "",
      subject: "",
      articles: []
    };

    $scope.templates = [];
    $scope.addingArticle = false;
    $scope.rules = {};
    $rootScope.group = {};
    $rootScope.subGroup = {};
    $rootScope.index = {};
    $rootScope.subIndex = {};
    $scope.showFileAlert = false;

    $scope.types = {
      options: NEWSLETTER_TYPES
    };

    $scope.newsletters = {
      options: []
    };

    OptionsService.newsletters().then(function (options) {
      $scope.newsletters.options = options.data;

      angular.forEach($scope.newsletters.options, function (item, key) {
        $scope.templates[item.id] = item;
      });
    });

    $scope.cancel = function () {
      $location.path('/admin/newsletters');
    };

    $scope.save = function (item) {
      console.log('saved item', item);
      item.save().then(function () {
        $scope.rules = {};

        $rootScope.addOnlyAlert("success", "Newsletter saved successfully");
        $state.go('root.admin.newsletters.edit', item);
        $scope.$parent.loadData();

      }, function (data) {
        $rootScope.addOnlyAlert("danger", "Failed to save newsletter");
        $scope.rules = data;
      });
    };

    $scope.setTemplate = function (templateId) {
      var template = {};
      if (templateId == null) {
        template = $scope.template;
      } else {
        template = $scope.templates[templateId];
      }

      $scope.item.template_id = template.id;
      $scope.item.article_body = template.article_body;

      // Clear the articles
      angular.forEach($scope.item.article_body.children, function (group) {
        group.articles = [];
        if (group.children) {
          angular.forEach(group.children, function (group) {
            group.articles = [];
          });
        }
      });

      $scope.item.articles = [];
      $scope.item.top_content = template.top_content;
      $scope.item.bottom_content = template.bottom_content;
      $scope.item.subject = template.subject;
    };

    $scope.deleteAttachment = function (index, attachment) {
      if (confirm("Are you sure you want to delete: " + attachment.file + '?')) {
        Attachment.destroy(attachment.id);
        $scope.item.attachments.splice(index, 1);
        $rootScope.addAlert("success", "Attachment deleted successfully.");
      }
    };

    $scope.uploadFile = function (file, errFiles) {
      $scope.f = file;
      $scope.errFile = errFiles && errFiles[0];

      Upload.upload({
        url: '/api/newsletter/upload',
        data: {file: file}
      }).then(function (resp) {
        $scope.item.file = resp.data.file;
        var attachment = {
          file: $scope.item.file
        };

        $scope.item.attachments.push(attachment);
      }, function (resp) {
        $scope.showFileAlert = true;
        $timeout($scope.hideFileAlert, 3000);
      }, function (evt) {
        $scope.showFileAlert = true;
        $timeout($scope.hideFileAlert, 3000);
        file.progress = parseInt(100.0 * evt.loaded / evt.total);
      });
    };

    $scope.$watch('item.article_body', function (articleBody, oldValue, scope) {
      if (articleBody === false) {
        $scope.item.article_body = $scope.rootGroup;
        $scope.item.articles = [];
      }
    });

    $scope.groupTemplate = {
      title: '',
      children: [],
      articles: []
    };

    $scope.rootGroup = {
      title: 'root',
      children: [
        {
          title: '',
          children: [],
          articles: []
        }
      ]
    };

    if (!$scope.item.id) {
      console.log("testing and still testing...");
      $scope.item.article_body = $scope.rootGroup;
      $scope.item.articles = [];
    }

    $scope.addGroup = function (group) {
      console.log("Group:");
      console.log(group);

      group.children.push({
        title: '',
        children: [],
        articles: []
      });
    };

    $scope.hideFileAlert = function () {
      $scope.showFileAlert = false;
    };

    $rootScope.$watch('articles', function (articles) {
      if ($scope.addingArticle) {
        $scope.articleIds = [];
        $scope.articles = [];

        angular.forEach($rootScope.articles, function (value, key) {
          $scope.articleIds.push(key);
          $scope.item.articles[key] = value;

          if ($rootScope.groupArticles.indexOf(parseInt(key)) < 0) {
            var index = $scope.groupData.index;
            if ($scope.groupData.subGroup == false) {
              $scope.item.article_body.children[index].articles.push(parseInt(key));
            } else {
              var subIndex = $scope.groupData.subIndex;
              $scope.item.article_body.children[index].children[subIndex].articles.push(parseInt(key));
            }
          }
        });

        $scope.addingArticle = false;
      }
    });

    $scope.addArticle = function (group, subGroup, index, subIndex, articles) {
      $scope.addingArticle = true;
      $scope.groupData = {
        group: group,
        subGroup: subGroup,
        index: index,
        subIndex: subIndex
      };

      $rootScope.groupArticles = articles;
      $rootScope.group = group;
      $rootScope.subGroup = subGroup;
      $rootScope.index = index;
      $rootScope.subIndex = subIndex;

      var uibModalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'admin/modal/newsletter-articles.html',
        controller: 'ModalNewsletterArticlesController',
        controllerAs: '$modal',
        size: 'lg'
      });
    };

    $scope.removeItem = function removeItem (items, item) {
      var _confirm = confirm("Are you sure you want to remove this item?");
      if (!_confirm) {
        return;
      }

      angular.forEach(items, function (_item, i) {
        if (item === _item) {
          items.splice(i, 1);
        }
      })
    };

    $scope.removeArticleFromGroup = function (group, article) {
      if (!confirm("Are you sure you want to remove this article?")) {
        return;
      }

      for (var i = 0; i < group.articles.length; i++) {
        if (group.articles[i] == article) {
          group.articles.splice(i, 1);
        }
      }
    };
  });

