angular.module('genesisApp')
.factory('NewsFlashes', function (ModelCollection, NewsFlash) {
    function NewsFlashes() {
        this.$model = NewsFlash;
        this.configure({url: 'api/newsflashes'});
    }

    inherit(NewsFlashes, ModelCollection);
    return NewsFlashes;
})
.factory('NewsFlash', function (Model) {
    function NewsFlash(data) {
        this.hydrate(data);
        this.configure({url: 'api/newsflashes/:id'});
    }

    inherit(NewsFlash, Model);
    return NewsFlash;
});

