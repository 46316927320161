angular.module('genesisApp')
.factory('AuthService', function ($http) {
    var authService = {};

    authService.login = function (credentials) {
        return $http.post('/login', credentials);
    };

    authService.recoverPassword = function (credentials) {
        return $http.post('/password/email', credentials);
    };

    authService.resetPassword = function (credentials) {
        return $http.post('/password/reset', credentials);
    };

    authService.isAuthenticated = function () {
        return window.currentUser ? true : false;
    };

    return authService;
}).factory('AuthInterceptor', function ($rootScope, $q, AUTH_EVENTS) {
    return {
        responseError: function (response) {
            $rootScope.$broadcast({
                401: AUTH_EVENTS.notAuthenticated,
                403: AUTH_EVENTS.notAuthorized,
                419: AUTH_EVENTS.sessionTimeout,
                440: AUTH_EVENTS.sessionTimeout,
                200: AUTH_EVENTS.loginSuccess
            }[response.status], response);
            return $q.reject(response);
        }
    };
});

