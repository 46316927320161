angular.module('genesisApp')
  .service('NewsletterLogLoader', function (NewsletterLog) {
    function NewsletterLogLoader () {
      var self = this;
      this.$on = false;


      this.start = function ($scope) {
        this.$on = true;
        loadData($scope);
      };

      this.stop = function ($scope) {
        this.$on = false;
      };

      function loadData ($scope) {
        if (self.$on === false) {
          return;
        }

        NewsletterLog.get().then(function (response) {
          console.log(response);
          console.log("AdminNewslettersController: loadLog");

          $scope.log = response.data;

          if ($scope.log.length === 0) {
            return;
          }

          setTimeout(function () {
            loadData($scope);
          }, 2000);
        });
      }
    }

    return new NewsletterLogLoader;
  })
  .controller('AdminNewslettersController', function ($scope, $rootScope, $interval, Newsletters, NewsletterLog, $uibModal, $location, NewsletterService, NewsletterLogLoader, $state) {

    $scope.query = $location.search();
    $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
    $scope.loadData = loadData;
    $scope.updateQuery = updateQuery;
    $scope.search = search;
    $scope.clearSearch = clearSearch;
    $scope.destroy = destroy;
    $scope.openPreview = openPreview;
    $scope.list = new Newsletters;
    $scope.log = null;

    loadData();

    function loadData () {
      $scope.loadingData = true;
      $scope.list.get($scope.query);
      $scope.list.$promise.then(function () {
        $scope.loadingData = false;
      });
    }

    $rootScope.$on('newsletterScheduled', function (event, data) {
      NewsletterLogLoader.start($scope);
    });

    NewsletterLogLoader.start($scope);

    function search () {
      $scope.query.page = 1;
      $scope.updateQuery();
    }

    function clearSearch () {
      $scope.query = {};
      $scope.search();
    }

    function updateQuery () {
      $location.search($scope.query);
      loadData();
    }

    $scope.send = function (item) {
      if (!confirm("Are you sure you want to send this newsletter?")) {
        return;
      }

      item.sending = true;

      var data = {
        'id': item.id,
        'is_test': false
      };

      NewsletterService.send(data).then(function (response) {
        item.sending = false;
        $rootScope.$broadcast('newsletterScheduled', {});

      });
    };

    function destroy (item) {
      if (confirm("Are you sure you want to delete: " + item.subject + '?')) {
        item.destroy().then(function () {
          $rootScope.addAlert('success', 'Newsletter deleted');
          loadData();
        }, function (data) {
          $rootScope.addAlert('error', 'Error deleting newsletter');
        })
      }
    }

    $scope.test = function (item) {

      $rootScope.newsletter = item;
      var uibModalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'admin/modal/newsletter-test.html',
        controller: 'ModalNewsletterTestController',
        controllerAs: '$modal',
        size: 'lg'
      });
    };

    function openPreview (newsletter) {
      var url = '/api/newsletters/' + newsletter.id + '/preview';
      window.open(url, 'newsletter', 'fullscreen=0,width=760,height=600,titlebar=no,toolbar=no,menubar=no', true);
    }
  });

