angular.module('genesisApp')
.factory('NewsletterAttachment', function (Model) {
    function NewsletterAttachment(data) {
        this.hydrate(data);
        this.configure({url: 'api/newsletter/attachments/destroy/:id'});
    }

    inherit(NewsletterAttachment, Model);
    return NewsletterAttachment;
});

