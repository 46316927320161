angular.module('genesisApp')
.controller('AdminBankEditController', function ($scope, $rootScope, Bank, $stateParams, $location, BANK_GROUPS, $state) {
        $scope.item = $stateParams.id ? (new Bank).find($stateParams.id) : (new Bank);
           
        $scope.rules = {};

        $scope.groups = {
            options: BANK_GROUPS
        };

        $scope.cancel = function () {
            $location.path('/admin/banks');
        };

        $scope.save = function (item) {
            item.save().then(function () {
                $rootScope.addOnlyAlert("success", "Bank saved successfully");
                $state.go('root.admin.banks.edit', item);
                $scope.$parent.loadData();

            }, function (resp) {
              $scope.rules = resp.data;
              $rootScope.addOnlyAlert("danger", "Failed to save bank");

            });
        };
    });

