angular.module('genesisApp')
.factory('OptionsService', ['$http', function ($http) {
    var optionsService = {};

    optionsService.countries = function () {
        return $http.get('/api/options/countries', {
        });
    };

    optionsService.companies = function () {
        return $http.get('/api/options/companies', {
        });
    };

    optionsService.sources = function () {
        return $http.get('/api/options/sources', {
        });
    };

    optionsService.articleTypes = function () {
        return $http.get('/api/types/articles', {
        });
    };

    optionsService.newsletterTypes = function () {
        return $http.get('/api/options/newslettertypes', {
        });
    };

    optionsService.banks = function () {
        return $http.get('/api/options/banks', {
        });
    };

    optionsService.bankGroups = function () {
        return $http.get('/api/groups/banks', {
        });
    };

    optionsService.newsletters = function () {
        return $http.get('/api/options/newsletters', {
        });
    };

    return optionsService;
}]);

