angular.module('genesisApp')
  .controller('BanksCountryGroupsController', function ($scope, $rootScope, $state, $stateParams, Banks, $location, BANK_GROUPS, BankGroupsByCountry) {
    console.log('BanksCountryGroupsController....');

    $scope.bankGroupsByCountry = (new BankGroupsByCountry);
    $scope.bankGroupsByCountry.get();

    $scope.bankGroupsByCountry.$promise.then(function () {
      console.log('$scope.bankGroupsByCountry', $scope.bankGroupsByCountry);

      if ($state.params.country_id) {
        angular.forEach($scope.bankGroupsByCountry, function (country) {
          if (country.id == $state.params.country_id) {
            $scope.selectedCountry = country;
          }
        })
      }

    });

  });

