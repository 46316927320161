angular.module('genesisApp')
  .controller('AdminRBPendingJobs', function ($scope, $rootScope, $stateParams, Period, FetchXML, JobXml, $interval, $timeout) {
    console.log('AdminRBPendingJobs...');

    var _interval;

    window.loadJobsLock = false;

    loadJobs();

    function loadJobs () {

      JobXml.get().then(function (response) {
        $scope.loadingJobs = false;
        $scope.jobs = response.data;

        if ($scope.jobs.length > 0) {
          setTimeout(function () {
            loadJobs();
          }, 1000);
        } else {
          window.loadJobsLock = false;
        }

      });
    }

    $rootScope.$on('rbJobsAdded', function(event, data) {
      loadJobs();
    });


  });

