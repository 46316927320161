angular.module('genesisApp')
  .controller('AdminSubscriptionEditController', function ($scope, $rootScope, Subscription, $stateParams, $location, $state, $uibModal) {
    $scope.item = $stateParams.id ? (new Subscription).find($stateParams.id) : (new Subscription);
    $scope.updateMemberIds = updateMemberIds;

    $scope.rules = {};
    $scope.memberIndex = {};

    $scope.cancel = function () {
      $location.path('/admin/subscriptions');
    };

    $scope.item.$promise.then(function (item) {
      updateMemberIds();

      //$scope.addMemberModal();

    });

    function updateMemberIds () {
      angular.forEach($scope.item.users, function (item) {
        $scope.memberIndex[item.id] = true;
      });
    }

    $scope.addMemberModal = function () {
      var uibModalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'admin/modal/add-members.html',
        controller: 'ModalAddMembersController',
        controllerAs: '$modal',
        size: 'lg',
        resolve: {
          memberIndex: $scope.memberIndex,
          $parentScope: $scope
        }
      });

      uibModalInstance.result.then(function (result) {
        console.log('uibModalInstance.result', result);
        if (result) {
          $scope.memberIndex = result[0];
          $scope.item.users = result[1];
        }
        //$ctrl.selected = selectedItem;
      }, function () {
        //$log.info('Modal dismissed at: ' + new Date());
      });

    };


    $scope.save = function (item) {
      item.save().then(function () {
        $scope.rules = {};
        $rootScope.addOnlyAlert("success", "Subscription saved successfully");
        $state.go('root.admin.subscriptions.edit', item);
        $scope.$parent.loadData();
      }, function (resp) {
        $rootScope.addOnlyAlert("danger", "Failed to save subscription");
        $scope.rules = resp.data;
      });
    };

    $scope.saveUser = function (user) {
      user.save().then(function () {
        },
        function (resp) {
          $rootScope.addAlert('error', 'Error saving user');
        });
    };

  })
  .controller('ModalAddMembersController', function ($scope, $rootScope, Users, $stateParams, $location, $state, memberIndex, $uibModalInstance, $parentScope) {

    $scope.list = new Users;
    $scope.query = {};
    $scope.query.subscriptionSort = $parentScope.item.id;
    $scope.memberIndex = angular.copy(memberIndex);
    $scope.users = angular.copy($parentScope.item.users);

    $scope.loadData = loadData;

    function loadData () {
      $scope.list.get($scope.query);
    }

    $scope.search = function () {
      loadData();
    };

    loadData();

    $scope.update = function () {
      $uibModalInstance.close([$scope.memberIndex, $scope.users]);
    };

    $scope.cancel = function (memberIndex) {
      $uibModalInstance.dismiss(null);
    };

    $scope.add = function (item) {
      $scope.memberIndex[item.id] = true;
      $scope.users.push(item);
    };

    $scope.remove = function (item) {
      $scope.memberIndex[item.id] = false;
      angular.forEach($scope.users, function (user, i) {
        if (user.id == item.id) {
          $scope.users.splice(i, 1);
        }
      });
    };

  });

