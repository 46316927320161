angular.module('genesisApp')
.constant('USER_TITLES', 
    [
        { id: 1, 'name': 'Mrs' }, 
        { id: 2, 'name': 'Mr' }, 
        { id: 3, 'name': 'Miss' }, 
        { id: 4, 'name': 'Dr' }, 
        { id: 5, 'name': 'Prof' }, 
        { id: 6, 'name': 'Sir' }
    ]
);

