angular.module('genesisApp')
  .controller('AuthController', function ($scope, $rootScope, $stateParams, User, AuthService, $state, CurrentUser) {
    console.log("AuthController...");

    $scope.credentials = {};
    $scope.credentials.token = $stateParams.token ? $stateParams.token : null;
    $scope.adminRedirectTo = "admin.users";
    $scope.user = new User;

    $scope.rules = [];

    // if ($rootScope.currentUser.id) {
    //   $state.go("root.home", {}, {reload: true});
    // }

    $scope.login = function (credentials) {
      AuthService.login(credentials).then(function (response) {

        //// set the current user
        //var redirectTo = $rootScope.previousState.name;
        //
        //// redirect to the correct interface
        //if (!redirectTo && response.data.user.access_level == 2) {
        //  redirectTo = $scope.adminRedirectTo;
        //}

        var redirectTo = 'root.home';

        if ($scope.currentRoute == 'root.news') {
          redirectTo = 'root.news';
        }
        if ($scope.currentRoute == 'root.banks') {
          redirectTo = 'root.banks';
        }
        if ($scope.currentRoute == 'root.reports') {
          redirectTo = 'root.reports';
        }

        $state.go(redirectTo, {}, {reload: true});

        setTimeout(function () {
          // window.location.href = '/#/news';
          window.location.reload();
        });

        //window.user = response.data.user;
        //
        //$rootScope.currentUser = new CurrentUser();

      }, function (response) {
        $scope.rules = response.data;
      });
    };

    $scope.register = function (user) {

      user.access_level = 1;

      console.log("registering user>>>>");
      console.log(user);

      user.configure({'url': '/register'});

      $scope.saving = true;

      user.save().then(function (response) {

        console.log('user.save().success', response);

        $scope.saving = false;

        $scope.rules = {};

        window.user = user;
        $rootScope.currentUser = new CurrentUser(user);
        window.location.href = '/#/profile';
        window.location.reload();


        //$state.go('root.profile');

      }, function (resp) {
        $scope.rules = resp.data;

        $scope.saving = false;
      });
    };

    $scope.recoverPassword = function (credentials) {
      $scope.submitting = true;

      AuthService.recoverPassword(credentials).then(function (response) {
        $scope.submitting = false;
        console.log("Recover password response:");
        console.log(response);

        // successful password recover
        $rootScope.addAlert('success', response.data.status);

        $scope.rules = {};
        $scope.credentials = {};

      }, function (response) {
        $scope.submitting = false;
        $scope.rules = response.data;
      });
    };

    $scope.resetPassword = function (credentials) {
      AuthService.resetPassword(credentials).then(function (response) {

        $scope.rules = {};

        // set the current user
        $rootScope.currentUser = response.data.user;
        //console.log(">>>>User<<<<");

        //console.log(response.data.user);

        $state.go("root.profile", {}, {reload: true});

        window.location.reload();

      }, function (response) {
        $scope.rules = response.data;
      });
    };


  });

