angular.module('genesisApp')
    .factory('FetchXML', ['$http', function ($http) {
        var FetchXML = {};

        FetchXML.import = function (params) {
            return $http.post('/api/reservebank/fetchXML', params);
        };

        return FetchXML;
    }]);

