angular.module('genesisApp')
.controller('ProfileController', ['$scope', '$rootScope', 'User', '$state', '$location', 'OptionsService', 'USER_TITLES', 'ACCESS_LEVELS',
    function ($scope, $rootScope, User, $state, $location, OptionsService, USER_TITLES, ACCESS_LEVELS) {
        console.log("ProfileController...");

        console.log("Current user:");
        console.log($rootScope.currentUser);

        $scope.loadData = loadData;
        $scope.profile = $rootScope.currentUser;

        function loadData () {
            $scope.profile.find($rootScope.currentUser.id);
        }

        $scope.loadData();

        $scope.password = null;

        $scope.rules = {};
        $scope.isPasswordChange = false;

        $scope.titles = {
            options: USER_TITLES
        };

        $scope.accessLevels = {
            options: ACCESS_LEVELS
        };

        $scope.companies = {
            options: []
        };

        $scope.newsletterTypes = {
            options: []
        };

        $scope.changePassword = function (isPasswordChange) {
            if (!isPasswordChange) {
                $scope.isPasswordChange = true;
            } else {
                $scope.isPasswordChange = false;
                $scope.profile.password = null;
                $scope.profile.password_confirmation = null;
            }
        };

        $scope.randomPassword = function(length) {
            var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
            var pass = "";
            for (var x = 0; x < length; x++) {
                var i = Math.floor(Math.random() * chars.length);
                pass += chars.charAt(i);
            }
            return pass;
        };

        $scope.cancel = function () {
            $location.path('/');
        };

        $scope.save = function (profile) {
            profile.save().then(function () {

                $rootScope.currentUser = profile;
                $scope.rules = {};

                $rootScope.addOnlyAlert("success", "Profile saved successfully.");

                $scope.loadData();

                $scope.isPasswordChange = false;

            }, function (data) {
                $scope.rules = data;
                $rootScope.addOnlyAlert("danger", "Unable to update profile");
            });
        };

        //OptionsService.companies().then(function (options) {
        //    $scope.companies.options = options.data;
        //    $scope.companies.options.push({id: 0, name: "Other"});
        //});
        //
        //OptionsService.newsletterTypes().then(function (options) {
        //    $scope.newsletterTypes.options = options.data;
        //});

    }])
    .controller('ProfileSidebarController', function ($scope, $http, $location) {
        console.log('ProfileSidebarController...');
});

