angular.module('genesisApp')
.controller('AdminSourcesController', ['$scope', '$rootScope', 'Sources', '$location', '$state',
    function ($scope, $rootScope, Sources, $location, $state) {
        console.log('AdminSourcesController...');

        $scope.query = $location.search();
        $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
        $scope.loadData = loadData;
        $scope.updateQuery = updateQuery;
        $scope.search = search;
        $scope.clearSearch = clearSearch;
        $scope.destroy = destroy;
        $scope.list = new Sources;

        loadData();

        function loadData() {
            $scope.list.get($scope.query);
            $scope.list.$promise.then(function () {
            });
        }

        function search() {
            $scope.query.page = 1;
            $scope.updateQuery();
        }

        function clearSearch() {
            $scope.query = {};
            $scope.search();
        }

        function updateQuery() {
            $location.search($scope.query);
            loadData();
        }

        $scope.edit = function (sourceId) {
            $state.go('root.admin.source.edit', {id: sourceId});
        };

        function destroy(item) {
            if (confirm("Are you sure you want to delete: " + item.title + '?')) {
                item.destroy().then(function () {
                    $rootScope.addOnlyAlert('success', 'Source deleted successfully.');
                    loadData();
                }, function (data) {
                    $rootScope.addOnlyAlert('error', 'Error deleting source.');
                });
            }
        }
    }]);    

