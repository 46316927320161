angular.module('genesisApp')
  .factory('Newsletters', function (ModelCollection, Newsletter) {
    function Newsletters () {
      this.$model = Newsletter;
      this.configure({url: 'api/newsletters'});
    }

    inherit(Newsletters, ModelCollection);
    return Newsletters;
  })
  .factory('Newsletter', function (Model) {
    function Newsletter (data) {
      this.hydrate(data);
      this.configure({url: 'api/newsletters/:id'});
    }

    inherit(Newsletter, Model);
    return Newsletter;
  });

