angular.module('genesisApp')
.constant('NEWSLETTER_TYPES', 
    [
        { id: 1, name: 'Daily News' },
        //{ id: 2, name: 'Middle East' },
        { id: 3, name: 'African News' }, 
        { id: 4, name: 'Payments' }
    ]
);

