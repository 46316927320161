angular.module('genesisApp')
.factory('Banks', function (ModelCollection, Bank) {
    function Banks (data) {
        this.$model = Bank;
        this.configure({url: 'api/banks'});
        this.hydrate(data);
    }

    inherit(Banks, ModelCollection);
    return Banks;
})
.factory('Bank', function (Model) {
    function Bank (data) {
        this.hydrate(data);
        this.configure({url: 'api/banks/:id'});
    }

    inherit(Bank, Model);

    return Bank;
});

