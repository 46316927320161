angular.module('genesisApp')
.factory('Statements', function (ModelCollection, Statement) {
    function Statements() {
        this.$model = Statement;
        this.configure({url: 'api/statements'});
    }

    inherit(Statements, ModelCollection);
    return Statements;
})
.factory('Statement', function (Model) {
    function Statement(data) {
        this.hydrate(data);
        this.configure({url: 'api/statements/:id'});
    }

    inherit(Statement, Model);
    return Statement;
});

