angular.module('genesisApp')
  .controller('FindAccountController', function ($scope, $rootScope, $http, $stateParams, User, AuthService, $state, CurrentUser) {
    console.log('FindAccountController...');

    $scope.credentials = {};

    $scope.findLoading = false;

    $scope.findAccount = function (credentials) {
      console.log('findAccount...');

      $scope.findLoading = true;

      $http.post('/api/find-account/reset', $scope.credentials).then(function (resp) {
        $scope.findLoading = false;
        $rootScope.addAlert('success', resp.data.status);
        $scope.credentials.email = '';

      }, function (resp) {
        $scope.findLoading = false;

        console.log('resp.data', resp.data);

        if (resp.data && resp.data.email) {
          $rootScope.addAlert('error', resp.data.email);
          $scope.credentials.email = '';
        }
      });
    };


    $scope.requestAccess = function (credentials) {
      console.log('findAccount...');

      $scope.findLoading = true;

      $http.post('/api/find-account/request', $scope.credentials).then(function (resp) {
        $scope.findLoading = false;
        $rootScope.addAlert('success', resp.data.status);
        $scope.credentials = {};

      }, function (resp) {
        $scope.findLoading = false;

        console.log('resp.data', resp.data);

        if (resp.data && resp.data.email) {
          $rootScope.addAlert('error', resp.data.email);
        }
      });

    };


  });

