angular.module('genesisApp')
  .directive("subscriptionStatus", function () {
    return {
      restrict: "E",
      template: "<div class=\"label subscription-label\" ng-class=\"{'label-success': status == 'active', " +
      "'label-info': status == 'trial', " +
      "'label-danger': status == 'disabled'" +
      "}\">{{ text || status }}</div>",
      scope: {
        status: "=",
        text: "="
      },
      link: function ($scope) {
      }
    };
  })
;

