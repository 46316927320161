angular.module('genesisApp')
  .factory('NewsletterSchedules', function (ModelCollection, Newsletter) {
    function NewsletterSchedules () {
      this.$model = Newsletter;
      this.configure({url: 'api/newsletters'});
    }

    inherit(NewsletterSchedules, ModelCollection);
    return NewsletterSchedules;
  })
  .factory('NewsletterSchedule', function (Model) {
    function NewsletterSchedule (data) {
      this.hydrate(data);
      this.configure({url: 'api/newsletter-schedules/:id'});
    }

    inherit(NewsletterSchedule, Model);
    return NewsletterSchedule;
  })
  .factory('NewsletterRecipient', function (Model) {
    function NewsletterRecipient (data) {
      this.hydrate(data);
      this.configure({url: 'api/newsletter-recipients/:id'});
    }

    inherit(NewsletterRecipient, Model);
    return NewsletterRecipient;
  })
  .factory('NewsletterScheduleRecipients', function (ModelCollection, NewsletterRecipient) {
    function NewsletterScheduleRecipients () {
      this.$model = NewsletterRecipient;
      this.configure({url: 'api/newsletter-schedules/:id/recipients'});
    }

    inherit(NewsletterScheduleRecipients, ModelCollection);
    return NewsletterScheduleRecipients;
  })
;

