angular.module('genesisApp').directive('headerSlideup', function ($location) {
  return {
    restrict: 'A',
    transclude: true,
    scope: {},
    template: "<div ng-transclude></div>",
    link: function ($scope, element, attrs) {
      var sidebarTop;

      function onScrollEventHandler (ev) {
        var sidebar = document.getElementById('sidebar');

        if (!sidebar) {
          return;
        }

        if (!sidebarTop) {
          sidebarTop = parseInt(window.getComputedStyle(sidebar, null).getPropertyValue("top"), 10);
        }

        var headerHeight = document.getElementById('header').offsetHeight;

        var top = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
        var diff = Math.min(top, headerHeight);

        //console.log('headerSlideup', top, diff);

        element[0].style.marginTop = '-' + diff + 'px';
        sidebar.style.top = (sidebarTop - diff) + 'px';
      }

      var el = window;

      if (el.addEventListener) {
        el.addEventListener('scroll', onScrollEventHandler, false);
      } else if (el.attachEvent) {
        el.attachEvent('onscroll', onScrollEventHandler);
      }

    }
  };
});

