angular.module('genesisApp')
  .directive('newsBlock', function (ARTICLE_TYPES, ArticleService, Articles) {
    return {
      restrict: 'AE',
      templateUrl: 'news-block.html',
      scope: {
        articleType: '=',
        bank: '=',
        heading: '@',
        search: '=',
        limit: '@'
      },
      link: function ($scope, element, $attrs) {
        console.log("news block:", $scope);

        $scope.loadData = loadData;
        $scope.loadingData = false;

        $scope.$watch('bank.id', function () {
          if (typeof $scope.bank == "undefined" || ($scope.bank && $scope.bank.id)) {
            loadData();
          }
        });

        $scope.articles = new Articles;

        function loadData() {
          $scope.loadingData = true;

          var query = {
            limit: $scope.limit ?  $scope.limit : 5,
            article_type: $scope.articleType,
            bank_id: $scope.bank ? $scope.bank.id : null,
            search: $scope.search ? $scope.search : null
          };

          angular.forEach(ARTICLE_TYPES, function (type) {
            if(type.id == query.article_type){
              $scope.newsType = type;
            }
          });

          $scope.articles.get(query).$promise.then(function () {

          });

          //ArticleService.get(query).then(function (response) {
          //  console.log('ArticleService.get', query);
          //  $scope.articles = response.data;
          //  $scope.loadingData = false;
          //});
        }
      }
    };
  });

