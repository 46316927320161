
angular.module('genesisApp')
  .controller('ReportsBuilderController', function ($scope, $http, $location, $httpParamSerializer, $uibModal, $state, $q, $rootScope, Banks) {
    console.log('ReportsBuilderController...');
    console.log('$location.url()', $location.url());

    $scope.loadPreview = loadPreview;
    $scope.loadRowsMenu = loadRowsMenu;
    $scope.openAddRow = openAddRow;
    $scope.editColumn = editColumn;
    $scope.addColumn = addColumn;
    $scope.addHeaderRow = addHeaderRow;
    $scope.resolveSelectedRows = resolveSelectedRows;
    $scope.removeRow = removeRow;
    $scope.removeColumn = removeColumn;
    $scope.removeHeaderRow = removeHeaderRow;
    $scope.setHeaderColor = setHeaderColor;
    $scope.save = save;
    $scope.institutionNameIndex = {};
    $scope.report = {};
    $scope.report.rb_template = {};
    $scope.template = $scope.report.rb_template;

    //$scope.$headers = new HeadersDesigner();

    function loadData () {
      $scope.loading = true;
      loadRowsMenu().then(function () {
        loadReport().then(function () {
          $scope.rowsMenuPromise.then(function () {
            $scope.loading = false;
            resolveSelectedRows();
          })
        });
      });

      loadInstitutions();

      $http.get('/api/reports/date-menus').then(function (resp) {
        $scope.reportMenu = resp.data;
      });
    }

    loadData();

    $scope.$on('$locationChangeSuccess', function () {
      loadPreview();
    });

    function save () {
      var data = {
        report: $scope.report
      };

      if ($scope.report.uuid) {
        var reportPromise = $http.put('/api/reports/' + $scope.report.uuid, data);
      } else {
        var reportPromise = $http.post('/api/reports', data);
      }

      reportPromise.then(function (resp) {
        $state.go('root.reportsBuilder.edit', resp.data);

        loadData();

        $rootScope.$broadcast("customReportSaved");
      });

    }

    function setHeaderColor (header, bg) {
      header.b = bg;
      updateHeaderColors();
      loadPreview();
    }

    function updateHeaderColors () {
      // background colour inheritance
      var $headers = $scope.template.data.headers;

      for (var $rowIndex = 0; $rowIndex < $headers.length; $rowIndex++) {
        var $row = $headers[$rowIndex];
        for (var $colIndex = 0; $colIndex < $row.length; $colIndex++) {
          delete $row[$colIndex].bi;
        }
      }

      for (var $rowIndex = 0; $rowIndex < $headers.length; $rowIndex++) {
        var $row = $headers[$rowIndex];
        for (var $colIndex = 0; $colIndex < $row.length; $colIndex++) {

          (function () {
            var $col = $row[$colIndex];
            if ($col.b !== null) {
              console.log('$col', $col);

              var $$colIndex = $colIndex;

              while ($headers[$rowIndex] && $headers[$rowIndex][$$colIndex]) {
                $headers[$rowIndex][$$colIndex].bi = $col.b;

                var $$rowIndex = $rowIndex + 1;

                while ($headers[$$rowIndex] && $headers[$$rowIndex][$$colIndex] && $headers[$$rowIndex][$$colIndex].b === null) {
                  $headers[$$rowIndex][$$colIndex].bi = $col.b;
                  $$rowIndex++;
                }

                console.log($rowIndex, $colIndex, $headers);

                $$colIndex++;

                if (!$headers[$rowIndex][$$colIndex] || $headers[$rowIndex][$$colIndex].b !== null) {
                  break;
                }
              }
            }
          })();

        }
      }

    }

    function loadReport () {
      var uuid = $state.params.uuid || $state.params.copy;
      if (uuid) {
        var reportPromise = $http.get('/api/reports/' + uuid);

        reportPromise.then(function (resp) {
          $scope.report = resp.data;
          $scope.template = $scope.report.rb_template;


          if ($state.params.copy) {
            delete $scope.report.id;
            delete $scope.report.uuid;
            delete $scope.template.id;

            $scope.report.title = 'Custom ' + $scope.report.title;
          }

          updateHeaderColors();

          //$scope.$headers = new HeadersDesigner($scope.template.data.headers);

          angular.forEach($scope.template.data.columns, function (col, i) {
            col.formula = new Formula(col.formula.name, col.formula.args);
          });

          //setTimeout(updateHeadersDummy, 1);

          loadPreview();
        });

      } else {
        $scope.template.pivotType = 'elementsByBank';

        $scope.template.data = {

          headers: [
            [
              {v: 'Total', b: 0}
            ]
          ],
          columns: [
            {"type": "text", "formula": new Formula('verbatim', [{column: "total"}]), "format": "format-text"}
          ]
        };

        $scope.report.rows = [];

        reportPromise = $q.all([]);
      }

      return reportPromise;
    }

    var loadPreviewThrottle;

    function loadPreview () {
      $scope.reportLoading = true;

      clearTimeout(loadPreviewThrottle);

      loadPreviewThrottle = setTimeout(function () {
        var query = $location.search();
        var apiQueryStr = $httpParamSerializer(query);

        if (!$scope.template.data) {
          return;
        }

        $http.post('/api/reports/preview?' + apiQueryStr, {
          template: $scope.template,
          report: $scope.report
        }).then(function (resp) {
          $scope.reportLoading = false;
          $scope.report.compiled = resp.data.compiled;
        });
      }, 1000);
    }

    function loadRowsMenu () {
      $scope.rowsMenuLoading = true;
      $scope.rowsMenuPromise = $http.get('/api/reports/rows-menu');

      $scope.rowsMenuPromise.then(function (resp) {
        $scope.rowsMenu = resp.data;
        buildRowIndex($scope.rowsMenu);
      });

      $scope.institutionsMenu = $http.get('/api/reports/institutions-menu');
      $scope.institutionsMenu.then(function (resp) {
        angular.forEach(resp.data, function (inst) {
          $scope.institutionNameIndex[inst.code] = inst.name;
        });
      });

      return $q.when([
        $scope.rowsMenuPromise,
        $scope.institutionsMenu
      ]);
    }

    function loadInstitutions () {
      $scope.institutions = (new Banks).get({reportable: 1});
    }


    $scope.rowIndex = {};
    $scope.columnIndex = {};

    function buildRowIndex (data) {
      angular.forEach(data, function (series) {
        angular.forEach(series.rb_tables, function (table) {
          angular.forEach(table.rb_rows, function (row) {
            $scope.rowIndex[series.name] = $scope.rowIndex[series.name] || {};
            $scope.rowIndex[series.name][row.rowNumber] = row;
          });
          angular.forEach(table.rb_columns, function (col) {
            if (!$scope.columnIndex[col.columnName]) {
              $scope.columnIndex[col.columnName] = col;
            }
          });
        });
      });

    }


    //openAddRow();
    //openAddColumn();


    function openAddRow (size, parentSelector) {
      var modalInstance = $uibModal.open({
        windowClass: 'modal-add-row',
        templateUrl: 'reports/reports-add-row.html',
        controller: 'ReportsAddRowController',
        size: 'fluid',
        //appendTo: parentElem,
        resolve: {
          rowsMenuPromise: function () {
            return $scope.rowsMenuPromise;
          },
          template: function () {
            return $scope.template;
          },
          report: function () {
            return $scope.report;
          },
          institutionsMenu: function () {
            return $scope.institutionsMenu;
          },
          $parentScope: function () {
            return $scope;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        loadPreview();
        //$ctrl.selected = selectedItem;
      }, function () {
        //$log.info('Modal dismissed at: ' + new Date());
      });
    }

    function editColumn (col, addAfter) {
      var modalInstance = $uibModal.open({
        windowClass: 'modal-add-column',
        templateUrl: 'reports/reports-edit-column.html',
        controller: 'ReportsEditColumnController',
        size: 'fluid',
        //appendTo: parentElem,
        resolve: {
          col: function () {
            return col;
          },
          $parentScope: function () {
            return $scope;
          },
          rowsMenuPromise: function () {
            return $scope.rowsMenuPromise;
          },
          template: function () {
            return $scope.template;
          },
          report: function () {
            return $scope.report;
          }
        }
      });

      modalInstance.result.then(function (col) {
        if (addAfter) {
          console.log('add column', col);
          angular.forEach($scope.template.data.headers, function (headerRow, i) {
            var parts = [];
            if (i == 0) {

              if ( col.formula.nameFormatted !== 'verbatim' ) {
                parts.push(col.formula.nameFormatted);
              }

              parts.push($scope.columnIndex[col.formula.args[0].column].name);


              if (col.formula.args[0] && col.formula.args[0].monthOffset) {
                parts.push(col.formula.args[0].monthOffset + "Mts");
              }

              //if (col.formula.args[0].column !== 'total') {
              //  parts.push(col.formula.args[0].column);
              //}
              if (col.formula.args[1] && col.formula.args[1].monthOffset) {
                parts.push(col.formula.args[1].monthOffset + "Mts");
              }
              headerRow.push({v: parts.join(' - ')});
            } else {
              headerRow.push({v: ""});
            }
          });

          $scope.template.data.columns.push(col);
        }
        setTimeout(loadPreview, 1);
        //$ctrl.selected = selectedItem;
      }, function () {
        //$log.info('Modal dismissed at: ' + new Date());
      });
    }

    function addColumn () {
      var col = {
        formula: new Formula('verbatim', [])
      };

      editColumn(col, true);
    }

    function resolveSelectedRows (institutionCode) {
      console.log('resolveSelectedRows...');

      var rowsMenu = $scope.rowsMenu;
      var report = $scope.report;

      console.log('rowsMenu', rowsMenu);

      var rowNumbers = _.pluck(report.rows, 'row');
      var rowSeries = _.pluck(report.rows, 'series');
      var rowTables = _.pluck(report.rows, 'table');
      institutionCode = institutionCode || null;
      var selectedRowsIndex = {};

      angular.forEach(report.rows, function (row) {
        selectedRowsIndex[row.institution] = selectedRowsIndex[row.institution] || [];
        selectedRowsIndex[row.institution].push(row.row);
      });

      console.log('selectedRowsIndex', selectedRowsIndex);

      angular.forEach(rowsMenu, function (series) {
        if (!_.contains(rowSeries, series.name)) {
          return
        }

        angular.forEach(series.rb_tables, function (table) {
          if (!_.contains(rowTables, table.tableNumber)) {
            return
          }

          angular.forEach(table.rb_rows, function (row) {
            if (_.contains(selectedRowsIndex[institutionCode], row.rowNumber)) {
              console.log('row.$selected = true;');
              row.$selected = true;
              table.$expanded = true;
              series.$expanded = true;
            } else {
              row.$selected = false;
            }
          });

        });

      });
    }

    function HeadersDesigner (headerRows) {
      this.headerRows = headerRows || [];

      //this.getHeaderSets = function () {
      //  var $headersSets = [];
      //  var _headerRows = angular.copy(this.headerRows);
      //
      //  for (var $rowIndex = 0; $rowIndex < _headerRows.length; $rowIndex++) {
      //    var $headerSet = [];
      //    for (var $colIndex = 0; $colIndex < _headerRows[$rowIndex].length; $colIndex++) {
      //      var $_colIndex = $colIndex;
      //      var $value = _headerRows[$rowIndex][$colIndex].v;
      //      var $bg = _headerRows[$rowIndex][$colIndex].bg;
      //      if ($value) {
      //        var $stub = {
      //          value: $value,
      //          rowspan: 1,
      //          colspan: 1,
      //          rowIndex: $rowIndex,
      //          colIndex: $colIndex,
      //          bg: $bg
      //        };
      //
      //        // check ahead columns
      //        (function () {
      //          var $count = 1;
      //          for (var $i = ($colIndex + 1); $i < _headerRows[$rowIndex].length; $i++) {
      //            if (_headerRows[$rowIndex][$i].v !== "" || _headerRows[$rowIndex][$i].v === null) break;
      //            _headerRows[$rowIndex][$i].v = null;
      //            $count += 1;
      //            $_colIndex += 1;
      //          }
      //          $stub.colspan = $count;
      //        })();
      //
      //        // check below rows
      //        (function () {
      //          var $count = 1;
      //          for (var $i = ($rowIndex + 1); $i < _headerRows.length; $i++) {
      //            if (_headerRows[$i][$colIndex].v !== "" || _headerRows[$i][$colIndex].v === null) break;
      //            _headerRows[$i][$colIndex].v = null;
      //            $count += 1;
      //          }
      //          $stub.rowspan = $count;
      //        })();
      //
      //        $headerSet.push($stub);
      //      }
      //      $colIndex = $_colIndex;
      //    }
      //    $headersSets.push($headerSet);
      //  }
      //
      //  return $headersSets;
      //};

      //this.headerSets = this.getHeaderSets();

      //this.mergeRow = function (header) {
      //  var rowIndex = header.rowIndex + (header.rowspan - 1) + 1;
      //
      //  for (var i = 0; i < header.colspan; i++) {
      //    this.headerRows[rowIndex][header.colIndex + i] = "";
      //  }
      //
      //  this.headerSets = this.getHeaderSets();
      //};
      //
      //this.mergeColumn = function (header) {
      //  var colIndex = header.colIndex + (header.colspan - 1) + 1;
      //
      //  this.headerRows[header.rowIndex][colIndex] = "";
      //
      //  this.headerSets = this.getHeaderSets();
      //};
      //
      //this.splitRow = function (header) {
      //  var rowIndex = header.rowIndex + (header.rowspan - 1);
      //
      //  this.headerRows[rowIndex][header.colIndex] = "Label";
      //
      //  this.headerSets = this.getHeaderSets();
      //};
      //
      //this.splitColumn = function (header) {
      //  var colIndex = header.colIndex + (header.colspan - 1);
      //
      //  this.headerRows[header.rowIndex][colIndex] = "Label";
      //
      //  this.headerSets = this.getHeaderSets();
      //};
      //
      //this.selectedIsMerged = function () {
      //  var flag = false;
      //  angular.forEach(this.headerSets, function (headerSet) {
      //    angular.forEach(headerSet, function (header) {
      //      if (header.selected && (header.rowspan > 1 || header.colspan > 1)) {
      //        flag = true;
      //      }
      //    });
      //  });
      //  return flag;
      //};

    }

    function updateHeadersDummy () {
      //console.log('updateHeadersDummy...');
      //
      //var headersDummy = $('#headersDummy');
      //var headers = $('#headers');
      //
      //angular.forEach($scope.$headers.headerSets, function (headerSet) {
      //  angular.forEach(headerSet, function (header) {
      //    var headerCellId = '#h-' + header.rowIndex + '-' + header.colIndex;
      //    var headerCell = $(headerCellId);
      //    var w = headerCell.width();
      //    var h = headerCell.height();
      //
      //    var tr = headersDummy.find('tr')[header.rowIndex];
      //    var ave = Math.round(w*10 / header.colspan)/10;
      //    console.log(headerCellId, w, ave);
      //
      //    for (var i = 0; i < header.colspan; i++) {
      //      var cell = $(tr).find('th')[header.colIndex + i];
      //      $(cell).width(ave + 1);
      //    }
      //  });
      //});

    }

    function removeRow (row) {
      var result = confirm("Are you sure you want to remove this row?");
      if (!result) {
        return;
      }

      angular.forEach($scope.report.rows, function (_row, i) {
        if (row === _row) {
          $scope.report.rows.splice(i, 1);
        }
      });

      resolveSelectedRows();
      loadPreview();
    }

    function removeColumn (col) {
      var result = confirm("Are you sure you want to remove this column?");
      if (!result) {
        return;
      }

      angular.forEach($scope.template.data.columns, function (_col, i) {
        if (col === _col) {
          $scope.template.data.columns.splice(i, 1);
          angular.forEach($scope.template.data.headers, function (headerRow) {
            headerRow.splice(i, 1);
          });
        }

      });

      resolveSelectedRows();
      loadPreview();
    }

    function removeHeaderRow (row) {
      var result = confirm("Are you sure you want to remove this row?");
      if (!result) {
        return;
      }

      angular.forEach($scope.template.data.headers, function (_row, i) {
        if (row === _row) {
          $scope.template.data.headers.splice(i, 1);
        }
      });

      loadPreview();
    }

    function addHeaderRow () {
      var newRow = [];

      for (var i = 0; i < $scope.template.data.headers[0].length; i++) {
        newRow.push({v: "", b: null});
      }

      $scope.template.data.headers.push(newRow);
      updateHeaderColors();

    }

  })
  .controller('ReportsAddRowController', function ($uibModalInstance, $scope, rowsMenuPromise, template, report, institutionsMenu, $parentScope) {
    $scope.rowsMenu = $parentScope.rowsMenu;
    $scope.selectRow = selectRow;
    //$scope.selectColumn = selectColumn;
    $scope.saveRowSelection = saveRowSelection;
    $scope.institutionsMenu = institutionsMenu.data;
    $scope.institutionCode = null;

    $scope.$watch('institutionCode', function () {
      $parentScope.resolveSelectedRows($scope.institutionCode);
    });

    function selectRow (table, row) {
      if (row.$selected) {
        return;
      }

      row.$add = !row.$add;
    }

    function saveRowSelection () {
      var rowSets = [];
      console.log('rowsMenu', $scope.rowsMenu);
      //report.rows = [];

      angular.forEach($scope.rowsMenu, function (series) {
        angular.forEach(series.rb_tables, function (table) {

          angular.forEach(table.rb_rows, function (row) {
            if (row.$add) {
              report.rows.push({
                institution: $scope.institutionCode || null,
                series: row.series,
                table: row.tableNumber,
                row: row.rowNumber
              });
              row.$selected = true;
            }
          });

        });
      });

      $uibModalInstance.close(report);
    }
  })

  .controller('ReportsEditColumnController', function ($uibModalInstance, $scope, rowsMenuPromise, template, report, col, $parentScope) {
    $scope.rowsMenu = rowsMenuPromise;
    $scope.template = template;
    $scope.report = report;
    $scope.col = col;
    $scope.rowIndex = $parentScope.rowIndex;
    $scope.columnIndex = $parentScope.columnIndex;
    $scope.institutions = $parentScope.institutions;
    $scope.saveColumn = saveColumn;

    function saveColumn () {
      $uibModalInstance.close(col);
    }

    $scope.series = [];
    $scope.rowOptions = [];
    $scope.columnOptions = [];
    $scope.seriesOptions = [];
    $scope.tablesIndex = {};
    $scope.formulaOptions = (new Formula).formulaOptions();
    $scope.monthOptions = [
      {value: 0, label: 'Current month'},
      {value: 1, label: '1 Month'},
      {value: 3, label: '3 Months'},
      {value: 6, label: '6 Months'},
      {value: 12, label: '12 Months'},
      {value: 18, label: '18 Months'},
      {value: 24, label: '24 Months'},
      {value: "YTD", label: 'Year-to-date'}
    ];

    angular.forEach($scope.report.rows, function (rowSet) {
      $scope.series.push(rowSet.series);
    });

    $scope.$watch('col.formula.name', function () {
      col.formula.init();
    });

    /**
     * Table index
     */
    function updateReportTablesIndex () {
      angular.forEach($scope.report.rows, function (row) {
        $scope.tablesIndex[row.series] = $scope.tablesIndex[row.series] || {};
        $scope.tablesIndex[row.series][row.table] = $scope.tablesIndex[row.series][row.table] || [];
        $scope.tablesIndex[row.series][row.table].push(row.row);
      });
    }

    updateReportTablesIndex();

    /**
     * Build row/column options
     */
    console.log('build row/column options', $parentScope.rowsMenu, '$scope.tablesIndex', $scope.tablesIndex);
    angular.forEach($parentScope.rowsMenu, function (series) {
      $scope.seriesOptions[series.name] = series.name;

      if ($scope.series.indexOf(series.name) === -1) {
        return
      }
      angular.forEach(series.rb_tables, function (table) {
        $scope.rowOptions.push(table);
      });

      angular.forEach(series.rb_tables, function (table) {
        if ($scope.tablesIndex[series.name] && $scope.tablesIndex[series.name][table.tableNumber]) {
          $scope.columnOptions.push(table);
        }
      });
    });


  })
  .controller('ReportsBuilderSidebarController', function ($scope, $http, $rootScope) {
    console.log('ReportsBuilderSidebarController...');

    $scope.reports = [];
    $scope.loadData = loadData;

    loadData();



    $rootScope.$on("customReportSaved", function () {
      loadData();
    });

    function loadData () {
      $http.get('/api/reports/custom').then(function (resp) {
        $scope.reports = resp.data;
      });
    }

  });


angular.module('genesisApp').directive("contenteditable", function () {
  return {
    restrict: "A",
    require: "ngModel",
    scope: {
      ngChange: "&"
    },
    link: function (scope, element, attrs, ngModel) {

      function read () {
        var str = element.html();

        ngModel.$setViewValue(str);
      }

      ngModel.$render = function () {
        element.html(ngModel.$viewValue || "");
      };

      element.bind("blur keyup change", function () {
        scope.$apply(read);
      });

      scope.$watch('ngModel', function () {
        scope.ngChange();
      });

    }
  };
});

function Formula (name, args) {
  this.name = name;
  this.args = args || [];
  this.exists = !!(args && args.length > 0);
  this.formulaConfig = null;

  var self = this;

  this.$formulaOptions = [
    {
      group: 'Basic',
      value: 'verbatim',
      label: 'Column Value',
      description: '(Value)',
      args: [['total']],
      argLength: 1,
      title: '{column}'
    },
    {
      group: 'Basic',
      value: 'growth',
      label: 'Growth (%)',
      args: [['total'], ['total', 1]],
      description: '(Value1 / Value2 - 1) * 100',
      argLength: 2,
      title: 'Growth % - {monthOffset}Mts'
    },
    {
      group: 'Basic',
      value: 'growthValue',
      label: 'Growth (Value)',
      args: [['total'], ['total', 1]],
      description: 'Value1 - Value2',
      argLength: 2,
      title: 'Growth - {monthOffset}Mts'
    },
    {
      group: 'Basic',
      value: 'annualizedGrowth',
      label: 'Annualized Growth',
      args: [['total'], ['total', 12]],
      description: '(Value1/Value2)^N - 1) * 100',
      argLength: 2,
      title: 'Annualized Growth - {monthOffset}Mts'
    },
    {
      group: 'Basic',
      value: 'share',
      label: 'Share (%)',
      args: [['total'], ['total', 0, 'TOTAL']],
      description: '(Value1 / Value2) * 100',
      argLength: 2,
      title: 'Share % - {monthOffset}Mts'
    },
    {
      group: 'Advanced',
      value: 'shareOfGrowth',
      label: 'Share Growth (%)',
      args: [['total'], ['total', 0, 'TOTAL'], ['total'], ['total', 1, 'TOTAL']],
      description: '(Value1 - Value2) / (Value3 - Value4) * 100',
      argLength: 4,
      title: 'Share Growth % - {monthOffset}Mts'
    },
    {
      group: 'Advanced',
      value: 'shareChange',
      label: 'Share of Share Growth (%)',
      args: [['total'], ['total', 0, 'TOTAL'], ['total'], ['total', 1, 'TOTAL']],
      description: '(Value1/Value2 * 100) - (Value3/Value4 * 100)',
      argLength: 4,
      title: 'Share of Growth % - {monthOffset}Mts'
    },

    {
      group: 'Preset',
      value: 'shareOfMarket',
      label: 'Share of Market',
      args: [['total'], ['total', 0, 'TOTAL']],
      description: '(Value1 / Value2) * 100',
      argLength: 2,
      title: 'Share of Market - {monthOffset}Mts'
    },
    {
      group: 'Preset',
      value: 'shareOfTotalAssets',
      label: 'Share of Total Assets',
      args: [['total'], ['total', 0, 'TOTAL', 277, 'BA900']],
      description: '(Value1 / Value2) * 100',
      argLength: 2,
      title: 'Share of Total Assets - {monthOffset}Mts'
    }
  ];


  angular.forEach(this.args, function (arg, i) {
    self.args[i] = new Argument(arg.column, arg.monthOffset, arg.institution, arg.row, arg.table);
  });

  this.init = function () {
    var formulaConfig = _.findWhere(this.$formulaOptions, {value: this.name});
    this.formulaConfig = formulaConfig;

    if (formulaConfig) {
      for (var i = 0; i < formulaConfig.argLength; i++) {
        if (this.exists && this.args[i]) {

        } else if (formulaConfig.args[i]) {
          this.args[i] = new Argument(
            formulaConfig.args[i][0],
            formulaConfig.args[i][1],
            formulaConfig.args[i][2],
            formulaConfig.args[i][3],
            formulaConfig.args[i][4]
          );
        } else {
          this.args[i] = new Argument('total');
        }
      }

      if (this.args.length > formulaConfig.argLength) {
        this.args.splice(formulaConfig.argLength, 10);
      }

      this.nameFormatted = formulaConfig.label;
      this.description = formulaConfig.description;
    }



  };

  this.init();

}

Formula.prototype.formulaOptions = function () {
  return this.$formulaOptions;
};


function Argument ($column, $monthOffset, $institution, $row, $table) {
  this.column = $column;
  this.monthOffset = $monthOffset;
  this.institution = $institution;
  this.row = $row;
  this.table = $table;
}


