'use strict';
angular.module('genesisApp', [
    'ui.router',
    'ngAnimate',
    'ngResource',
    'ngSanitize',
    'ui.bootstrap',
    'daterangepicker',
    'ui.sortable',
    'ngFileUpload',
    'ngSanitize',
    'ncy-angular-breadcrumb',
    'uiSwitch',
    'angular.vertilize',
    'ui.select',
    'permission',
    'permission.ui'
  ])
  .constant('AUTH_EVENTS', {
    loginSuccess: 'auth-login-success',
    loginFailed: 'auth-login-failed',
    logoutSuccess: 'auth-logout-success',
    sessionTimeout: 'auth-session-timeout',
    notAuthenticated: 'auth-not-authenticated',
    notAuthorized: 'auth-not-authorized'
  })
  .directive('footer', function () {
    return {
      restrict: 'A',
      replace: true,
      templateUrl: "footer.html",
      controller: ['$scope', '$filter', function () {
      }]
    };
  })
  .config(function ($httpProvider, $breadcrumbProvider, $locationProvider) {
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    $httpProvider.interceptors.push([
      '$injector',
      function ($injector) {
        return $injector.get('AuthInterceptor');
      }
    ]);

    $locationProvider.hashPrefix('');

  })
  .run(function ($rootScope, $state, CurrentUser, AUTH_EVENTS, $sce, $location, $window) {
    // $window.ga('create', 'UA-79827239-2', 'auto');
    // $rootScope.$on('$stateChangeSuccess', function (event) {
    //   $window.ga('send', 'pageview', $location.path());
    // });

    window.dataLayer.push({
      'event': 'virtualPageview',
      'pageUrl': window.location.href,
      'pageTitle': document.title //some arbitrary name for the page/state
    });

    $rootScope.$on("$stateChangeSuccess", function (event, currentRoute, previousRoute) {
      window.scrollTo(0, 0);
    });

    console.log("current state:");
    console.log($state);

    $rootScope.$state = $state;
    $rootScope.currentRoute = "";
    $rootScope.currentUser = new CurrentUser(window.user);
    $rootScope.previousState = 'admin';
    $rootScope.redirectTo = 'admin';
    $rootScope.sendToLogin = 'login';
    $rootScope.redirectRegex = /login|register/;
    $rootScope.trustAsHtml = $sce.trustAsHtml;
    $rootScope.default_slug = 'south-africa';

    $rootScope.$watch('$state.current.name', function () {
      $rootScope.bodyClass = $state.current.name.split('.')[1];
    });
    $rootScope.baseUrl = window.baseUrl;

    $rootScope.datepickerOptions = {
      locale: {format: 'MMMM D, YYYY'},
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };

    document.getElementById("_token").value = window.Laravel.csrfToken;

    $rootScope.moment = moment;
    $rootScope.numeral = numeral;

    $rootScope.alerts = [];

    $rootScope.addAlert = function (type, msg) {
      $rootScope.alerts.push({'type': type, 'msg': msg});
    };
    $rootScope.addOnlyAlert = function (type, msg) {
      while ($rootScope.alerts.length > 0) {
        $rootScope.alerts.pop();
      }
      $rootScope.addAlert(type, msg);
    };

    $rootScope.closeAlert = function (index) {
      $rootScope.alerts.splice(index, 1);
    };

    $rootScope.$on('$stateChangeSuccess', function (event, to, toParams, from) {
      //console.log("top params:");
      //console.log(to);

      $rootScope.currentRoute = to.name;
      $rootScope.currentRouteTop = to.name.split('.')[0];
      $rootScope.currentRouteSecond = to.name.split('.')[1] ? to.name.split('.')[1] : null;

      //console.log("from params:");
      //console.log(from);
      //console.log("state changed.");

      $rootScope.previousState = from;
    });

    $rootScope.$on(AUTH_EVENTS.notAuthenticated, function () {
      console.log('AUTH_EVENTS.notAuthenticated...');
      //$rootScope.currentUser = {};
      //return $state.go('root.login');
    });

    $rootScope.$on(AUTH_EVENTS.sessionTimeout, function () {
      console.log('AUTH_EVENTS.sessionTimeout...');
      //$rootScope.currentUser = {};
      //return $state.go('root.login');
    });


  })
  .run(function (PermPermissionStore, PermRoleStore, $rootScope) {
    var currentUser = $rootScope.currentUser;

    PermPermissionStore.definePermission('accessAdminSection', /** @ngInject */ function ($rootScope) {
      console.log('accessAdminSection...');
      return !!($rootScope.currentUser.id && $rootScope.currentUser.access_level === 2);
    });

    PermPermissionStore.definePermission('accessUserSections', /** @ngInject */ function ($rootScope) {
      // console.log('accessUserSections...');
      return (!!$rootScope.currentUser.id);
    });

    PermPermissionStore.definePermission('accessAnonymousSections', /** @ngInject */ function ($rootScope) {
      // console.log('accessAnonymousSections...');
      return !($rootScope.currentUser && $rootScope.currentUser.id);
    });

    PermPermissionStore.definePermission('accessMemberSections', /** @ngInject */ function ($rootScope) {
      //console.log('accessMemberSections...', $rootScope.currentUser);
      return !!(
        $rootScope.currentUser && $rootScope.currentUser.id
        && $rootScope.currentUser.subscription
        && $rootScope.currentUser.subscription.status !== 'disabled'
      );
    });

    PermPermissionStore.definePermission('accessMemberDataSections', /** @ngInject */ function ($rootScope) {
      //console.log('accessMemberSections...', $rootScope.currentUser);
      return !!(
        $rootScope.currentUser && $rootScope.currentUser.id
        && $rootScope.currentUser.subscription
        && $rootScope.currentUser.subscription.status !== 'disabled'
        && ($rootScope.currentUser.access_data == true)
      );
    });

    PermRoleStore.defineRole('ADMIN', ['accessAdminSection']);
    PermRoleStore.defineRole('MEMBER', ['accessMemberSections']);
    PermRoleStore.defineRole('MEMBER-DATA', ['accessMemberDataSections']);
    PermRoleStore.defineRole('ANONYMOUS', ['accessAnonymousSections']);
    PermRoleStore.defineRole('USER', ['accessUserSections']);

  })
  .factory('moment', function ($window) {
    return $window.moment;
  })
  .directive('bindHtmlCompile', ['$compile', function ($compile) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.$watch(function () {
          return scope.$eval(attrs.bindHtmlCompile);
        }, function (value) {
          // Incase value is a TrustedValueHolderType, sometimes it
          // needs to be explicitly called into a string in order to
          // get the HTML string.
          element.html(value && value.toString());
          // If scope is provided use it, otherwise use parent scope
          var compileScope = scope;
          if (attrs.bindHtmlScope) {
            compileScope = scope.$eval(attrs.bindHtmlScope);
          }
          $compile(element.contents())(compileScope);
        });
      }
    };
  }]);



