angular.module('genesisApp')
.controller('BanksController', function ($scope, OptionsService, BankGroupsByCountry, Banks) {
  $scope.bankGroupsByCountry = (new BankGroupsByCountry);
  $scope.banks = (new Banks);
  $scope.bankGroupsByCountry.get();
  $scope.banks.get();
  $scope.search = search;
  $scope.bankQuery = {};

  function search () {
    $scope.banks.get($scope.bankQuery);
  }

})
.controller('BanksSidebarController', function ($scope, $state, $http, $location, BankGroupsByCountry) {
  console.log('BanksSidebarController...');

  $scope.bankGroupsByCountry = (new BankGroupsByCountry);
  $scope.bankGroupsByCountry.get();

  $scope.bankGroupsByCountry.$promise.then(function () {

    update();

  });



  $scope.$on('$stateChangeSuccess', function (value) {
    console.log('BanksSidebarController: $stateChangeSuccess...');
    update();
  });

  $scope.selectCountry = function (country) {
    $state.go('root.banks.country', {country_id: country.id});
  };

  function update () {
    console.log('BanksSidebarController update...');
    console.log('BanksSidebarController $scope.bankGroupsByCountry', $scope.bankGroupsByCountry);

    if ($state.params.country_id) {
      angular.forEach($scope.bankGroupsByCountry, function (country) {
        if (country.id == $state.params.country_id) {
          $scope.selectedCountry = country;
        }
      })
    } else {
      $scope.selectedCountry = null;
    }
    if ($state.params.group_id) {
      angular.forEach($scope.selectedCountry.bank_groups, function (group) {
        if (group.id == $state.params.group_id) {
          $scope.selectedGroup = group;
        }
      });
    } else {
      $scope.selectedGroup = null;
    }

  }

});

