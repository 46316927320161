angular.module('genesisApp')
  .controller('AdminSubscriptionsController', ['$scope', '$rootScope', 'Subscriptions', '$location', '$state',
    function ($scope, $rootScope, Subscriptions, $location, $state) {
      console.log('AdminSubscriptionsController...');

      $scope.query = $location.search();
      $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
      $scope.loadData = loadData;
      $scope.updateQuery = updateQuery;
      $scope.search = search;
      $scope.clearSearch = clearSearch;
      $scope.destroy = destroy;
      $scope.list = new Subscriptions;

      loadData();

      function loadData() {
        $scope.list.get($scope.query);
        $scope.list.$promise.then(function () {
        });
      }

      function search() {
        $scope.query.page = 1;
        $scope.updateQuery();
      }

      function clearSearch() {
        $scope.query = {};
        $scope.search();
      }

      function updateQuery() {
        $location.search($scope.query);
        loadData();
      }

      $scope.edit = function (companyId) {
        $state.go('root.admin.subscription.edit', {id: companyId});
      };

      function destroy(item) {
        if (confirm("Are you sure you want to delete: " + item.name + '?')) {
          item.destroy().then(function () {
            $rootScope.addOnlyAlert('success', 'Subscription deleted');
            loadData();
          }, function (data) {
            $rootScope.addOnlyAlert('error', 'Error deleting subscription');
          });
        }
      }
    }]);

