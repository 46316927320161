angular.module('genesisApp')
.factory('Articles', function (ModelCollection, Article) {
    function Articles() {
        this.$model = Article;
        this.configure({url: 'api/articles'});
    }

    inherit(Articles, ModelCollection);
    return Articles;
})
.factory('Article', function (Model, ARTICLE_TYPES) {
    function Article(data) {
        this.hydrate(data);
        this.configure({url: 'api/articles/:id'});
    }

    inherit(Article, Model);

    Article.$articleTypeIndex = (function () {
        var index = {};
        angular.forEach(ARTICLE_TYPES, function (item) {
            index[item.id] = item.name;
        });
        return index;
    })();

    Article.prototype.getTypeFormatted = function () {
        return Article.$articleTypeIndex[this.type];
    };

    Article.prototype.beforeSave = function (data) {
        if (data.send_at) {
            data.send_at = moment(data.send_at).format('YYYY-MM-DD');
        }
        if (data.published_at) {
            data.published_at = moment(data.published_at).format('YYYY-MM-DD');
        }
        return data;
    };


    return Article;
});

