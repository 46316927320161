angular.module('genesisApp')
        .controller('AdminNewsletterPreviewController', ['$scope', '$rootScope', 'Newsletter', '$stateParams', '$location', 'NewsletterService',
            function ($scope, $rootScope, Newsletter, $stateParams, $location, NewsletterService) {
                $scope.item = $stateParams.id ? (new Newsletter).find($stateParams.id) : (new Newsletter);

                console.log("AdminNewsletterPreviewController");

                $scope.cancel = function () {
                    $location.path('/admin/newsletters');
                };

                $scope.send = function (item) {
                    if(item.status != 'Drafted'){
                        return false;
                    }

                    console.log("preview ... stuff");
                    item.status = 'Pending';

                    var data = {
                        'id': item.id,
                        'is_test': false
                    };

                    NewsletterService.send(data).then(function (response) {

                        console.log("testing >>>>");

                        $location.path('/admin/newsletters');
                    });
                };
            }]);
;

