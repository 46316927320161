angular.module('genesisApp')
.factory('Sources', function (ModelCollection, Source) {
    function Sources() {
        this.$model = Source;
        this.configure({url: 'api/sources'});
    }

    inherit(Sources, ModelCollection);
    return Sources;
})
.factory('Source', function (Model) {
    function Source(data) {
        this.hydrate(data);
        this.configure({url: 'api/sources/:id'});
    }

    inherit(Source, Model);
    return Source;
});

