angular.module('genesisApp')
  .controller('AdminStatementEditController', function ($scope, $rootScope, Statement, $stateParams, $location, STATEMENT_TYPES, OptionsService, Upload, $state) {
    $scope.item = $stateParams.id ? (new Statement).find($stateParams.id) : (new Statement);

    $scope.isChangeFile = false;

    $scope.changeFile = function (isChangeFile) {
      $scope.isChangeFile = isChangeFile;
    };

    $scope.rules = {};
    $scope.types = {
      options: STATEMENT_TYPES
    };

    $scope.banks = {
      options: []
    };

    OptionsService.banks().then(function (options) {
      $scope.banks.options = options.data;
    });

    $scope.cancel = function () {
      $location.path('/admin/statements');
    };

    $scope.save = function (item) {
      item.save().then(function () {
        $scope.rules = {};
        $rootScope.addAlert("success", "Statements saved successfully.");
        $state.go('root.admin.statements.edit', item);
        $scope.$parent.loadData();
      }, function (resp) {
        $scope.rules = resp.data;
        $rootScope.addAlert("success", "Failed to save statement");

      });
    };

    $scope.uploadFile = function (file, errFiles) {
      $scope.f = file;
      $scope.errFile = errFiles && errFiles[0];

      Upload.upload({
        url: '/api/statement/upload',
        data: {file: file}
      }).then(function (resp) {
        $scope.item.file = resp.data.file;
        console.log(resp);
      }, function (resp) {
        console.log('Error status: ' + resp.status);
      }, function (evt) {
        file.progress = parseInt(100.0 * evt.loaded / evt.total);
      });
    };

    $scope.$watch('item.type', function (type, oldValue, scope) {
      if (type !== undefined && scope.types.model) {
        scope.types.model.id = type;
      }
    });
  });

