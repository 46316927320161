angular.module('genesisApp')
.controller('AdminSourceEditController', ['$scope', '$rootScope', 'Source', '$stateParams', '$location',
    function ($scope, $rootScope, Source, $stateParams, $location) {
        $scope.item = $stateParams.id ? (new Source).find($stateParams.id) : (new Source);
           
        $scope.rules = {};

        $scope.cancel = function () {
            $location.path('/admin/sources');
        };

        $scope.save = function (item) {
            item.save().then(function () {
                $rootScope.addOnlyAlert("success", "Source saved successfully");
                $scope.$parent.loadData();
                $scope.rules = {};
                $state.go('root.admin.sources.edit', item);
            }, function (resp) {
                $scope.rules = resp.data;
                $rootScope.addOnlyAlert("error", "Failed to save source");
            });
        };
    }]);

