angular.module('genesisApp')
  .controller('NewsController', function ($scope, $rootScope, $location, ARTICLE_TYPES) {
    $scope.sections = ARTICLE_TYPES;

  })
  .controller('NewsSidebarController', function ($scope, $rootScope, $state, $stateParams, $location, ARTICLE_TYPES, OptionsService) {
    console.log('NewsSidebarController...');

    $scope.default_slug = 'south-africa';
    $scope.country_slug = $scope.default_slug;

    $scope.$watch('$root.country_slug', function () {
      console.log("changing the country slug:" + $rootScope.country_slug);
    });

    $scope.$watch('$root.article_type', function () {
      $scope.article_type = $rootScope.article_type;
    });

    console.log("$scope.article_type", $scope.article_type);

    $scope.select = select;

    $scope.countries = {
      options: []
    };

    function select (country_slug) {
      console.log("Country: " + country_slug);
      console.log("Article type: " + $scope.article_type);

      $state.go('root.news.section', {country_slug: country_slug, article_type: $scope.article_type});
    }

    OptionsService.countries().then(function (options) {
      $scope.countries.options = options.data;
    });

    console.log("News countries...");
    console.log($scope.countries);

    $scope.articleTypes = ARTICLE_TYPES;
    console.log(ARTICLE_TYPES);
  });

