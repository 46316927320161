angular.module('genesisApp')
    .factory('JobXml', ['$http', function ($http) {
        var JobXml = {};

        JobXml.get = function (params) {
            return $http.get('/api/reservebank/jobs', {params: params});
        };

        return JobXml;
    }]);

