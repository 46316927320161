angular.module('genesisApp')
.factory('Attachment', ['$http', function ($http) {
    var attachment = {};

    attachment.destroy = function (id) {
        return $http({url: '/api/newsletter/attachments/' + id, method: 'DELETE'});
    };

    return attachment;
}]);

