angular.module('genesisApp')
.factory('Countries', function (ModelCollection, Country) {
    function Countries () {
        this.$model = Country;
        this.configure({url: 'api/countries'});
    }

    inherit(Countries, ModelCollection);
    return Countries;
})
.factory('Country', function (Model, BankGroups) {
    function Country (data) {
        this.hydrate(data);
        this.configure({url: 'api/countries/:id'});
    }

    inherit(Country, Model);

    Country.prototype.beforeHydrate = function (data) {
        data.bank_groups = new BankGroups(data.bank_groups);
    };

    return Country;
});

