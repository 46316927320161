angular.module('genesisApp')
  .controller('AdminCountryEditController', function ($scope, $rootScope, Country, $stateParams, $location, $state) {
    $scope.item = $stateParams.id ? (new Country).find($stateParams.id) : (new Country);

    $scope.rules = {};

    $scope.cancel = function () {
      $location.path('/admin/countries');
    };

    $scope.save = function (item) {
      item.save().then(function () {
        $scope.rules = {};
        $rootScope.addOnlyAlert("success", "Country saved successfully");
        $state.go('root.admin.countries.edit', item);
        $scope.$parent.loadData();
      }, function (resp) {
        $rootScope.addOnlyAlert("danger", "Failed to save country");
        $scope.rules = resp.data;
      });
    };
  });

