angular.module('genesisApp')
    .factory('Institution', ['$http', function ($http) {
        var Institution = {};

        Institution.get = function (params) {
            return $http.get('/api/reservebank/institutions', {params: params});
        };

        return Institution;
    }]);

