angular.module('genesisApp')
.factory('Companies', function (ModelCollection, Company) {
    function Companies() {
        this.$model = Company;
        this.configure({url: 'api/companies'});
    }

    inherit(Companies, ModelCollection);
    return Companies;
})
.factory('Company', function (Model) {
    function Company(data) {
        this.hydrate(data);
        this.configure({url: 'api/companies/:id'});
    }

    inherit(Company, Model);
    return Company;
});

