angular.module('genesisApp')
.constant('ARTICLE_TYPES', 
    [
        //{
        //    id: 1,
        //    name: 'News Archive',
        //    description: "This section contains national and international news relevant to the financial sector in South Africa and the rest of Africa. It provides easy links to articles from all major publications and is governed by your country selection in the left menu."
        //},
        {
            id: 2,
            name: 'Daily News',
            description: "This section contains national and international news relevant to the financial sector in South Africa and the rest of Africa. It provides easy links to articles from all major publications and is governed by your country selection in the left menu."
        },
        {
            id: 3,
            name: 'Economic Reports',
            description: "This section contains reports and other documents relating to the economic environment in South Africa."
        },
        {
            id: 4,
            name: 'Regulatory Reports',
            description: "This section contains reports, documents, charters and legislation relevant to the regulatory environment in which the South African banking sector operates."
        },
        {
            id: 5,
            name: 'Sector Reports',
            description: "This section contains reports and other documents highlighting issues and events affecting the South African banking sector."
        }
    ]
);

