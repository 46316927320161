angular.module('genesisApp')
  .controller('BanksGroupController', function ($scope, $rootScope, $stateParams, Banks, $location, BANK_GROUPS, BankGroupsByCountry, $state) {
    console.log('BanksGroupController....');

    $scope.id = $stateParams.group_id;
    $scope.query = $location.search();

    $scope.query.group_id = $scope.id;
    $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;

    $scope.loadData = loadData;
    $scope.list = new Banks;

    $scope.bankGroupsByCountry = (new BankGroupsByCountry);
    $scope.bankGroupsByCountry.get();

    $scope.bankGroupsByCountry.$promise.then(function () {
      console.log('$scope.bankGroupsByCountry', $scope.bankGroupsByCountry);

      if ($state.params.country_id) {
        angular.forEach($scope.bankGroupsByCountry, function (country) {
          if (country.id == $state.params.country_id) {
            $scope.country = country;
          }
        });
      }
      if ($state.params.group_id) {
        angular.forEach($scope.country.bank_groups, function (group) {
          if (group.id == $state.params.group_id) {
            $scope.group = group;
          }
        });
      }


    });


    loadData();

    function loadData () {
      $scope.loadingData = true;
      $scope.list.get($scope.query);
      $scope.list.$promise.then(function () {
        $scope.loadingData = false;

      });
    }
  });

