angular.module('genesisApp')
.controller('AdminNewsFlashesController', ['$scope', '$rootScope', 'NewsFlashes', '$location',
    function ($scope, $rootScope, NewsFlashes, $location) {
        console.log('AdminNewsFlashesController...');

        $scope.query = $location.search();
        $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
        $scope.loadData = loadData;
        $scope.updateQuery = updateQuery;
        $scope.search = search;
        $scope.clearSearch = clearSearch;
        $scope.destroy = destroy;
        $scope.list = new NewsFlashes;

        loadData();

        function loadData() {
            $scope.list.get($scope.query);
            $scope.list.$promise.then(function () {
            });
        }

        function search() {
            console.log('search...');
            $scope.query.page = 1;
            $scope.updateQuery();
        }

        function clearSearch() {
            $scope.query = {};
            $scope.search();
        }

        function updateQuery() {
            $location.search($scope.query);
            loadData();
        }

        function destroy(item) {
            if (confirm("Are you sure you want to delete: " + item.heading + '?')) {
                item.destroy().then(function () {
                    $rootScope.addAlert('success', 'News flash deleted');
                    loadData();
                }, function (data) {
                    $rootScope.addAlert('error', 'Error deleting new flash');
                });
            }
        }

    }]);    

