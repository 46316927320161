angular.module('genesisApp')
  .factory('BankGroups', function (ModelCollection, BankGroup) {
    function BankGroups(data) {
      this.$model = BankGroup;
      this.configure({url: 'api/bank-groups'});
      this.hydrate(data);
    }

    inherit(BankGroups, ModelCollection);
    return BankGroups;
  })

  .factory('BankGroup', function (Model, Banks) {
    function BankGroup(data) {
      this.hydrate(data);
      this.configure({url: 'api/banks/:id'});
    }

    inherit(BankGroup, Model);

    BankGroup.prototype.beforeHydrate = function (data) {
      data.banks = new Banks(data.banks);
    };

    return BankGroup;
  })
  .factory('BankGroupsByCountry', function (ModelCollection, Country) {
    function BankGroupsByCountry() {
      this.$model = Country;
      this.configure({url: 'api/bank-groups/by-country'});
    }
    inherit(BankGroupsByCountry, ModelCollection);

    return BankGroupsByCountry;
  })
;

