angular.module('genesisApp').directive('reportDateMenu', function($location) {
  return {
    restrict: 'E',
    //transclude: true,
    templateUrl: 'report-date-menu.html',
    scope: {
      menu: '=',
      series: '=',
      report: '='
    },
    link: function($scope, element, attrs) {

      console.log('reportDateMenu...');

      $scope.currentMenu = {};
      $scope.currentInstitutions = [];
      $scope.menuInstitution = null;

      $scope.dateChanged = function () {
        var query = $location.search();

        console.log('$scope.dateChanged...');
        console.log('$scope.menuDate', $scope.menuDate);

        if ($scope.menuDate && $scope.menuDate.institutions) {
          $scope.currentInstitutions = $scope.menuDate.institutions;
        }

        query.date = $scope.menuDate.date;

        $location.search(query);
      };

      $scope.institutionChanged = function () {
        var query = $location.search();

        console.log('$scope.institutionChanged...');
        console.log('$scope.menuInstitution', $scope.menuInstitution);

        query.institution = $scope.menuInstitution.code;
        $location.search(query);
      };

      function updateCurrentMenu () {
        console.log("reportDateMenu updateCurrentMenu...");

        var query = $location.search();

        if ($scope.series) {

          if ($scope.menu && $scope.menu[$scope.series]) {
            $scope.currentMenu = $scope.menu[$scope.series];

            var selectedDate = _.where($scope.currentMenu, {date: query.date});

            if (!query.date || selectedDate.length === 0) {
              selectedDate = [_.first($scope.currentMenu)];
            }

            if (selectedDate && selectedDate[0]) {
              $scope.menuDate = selectedDate[0];
              $scope.dateChanged();
              var selectedInstitution = _.where($scope.currentInstitutions, {code: query.institution});

              if (!query.institution || selectedInstitution.length === 0) {
                var selectedInstitution = _.where($scope.currentInstitutions, {code: "TOTAL"});
              }

              if (selectedInstitution && selectedInstitution[0]) {
                $scope.menuInstitution = selectedInstitution[0];

                $scope.institutionChanged();
              }
            }

          }
        }
      }

      $scope.$watch('series', function () {
        updateCurrentMenu();
      });

    }
  };
});

