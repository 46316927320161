
function inherit (child, parent) {
  child.prototype = new parent();
  child.prototype.constructor = parent;
  return child;
}

angular.module('genesisApp')
  .service('ModelHelper', ['$q', function ($q) {
    function ModelHelper () {}

    ModelHelper.prototype.queryString = function queryString (obj, prefix) {
      var str = [];
      for (var p in obj) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[k];
        if (v === null || v === undefined) {
          v = '';
        }
        str.push(angular.isObject(v) ? queryString(v, k) : (k) + "=" + encodeURIComponent(v));
      }
      return str.join("&");
    };

    ModelHelper.prototype.createDummyPromise = function () {
      var promise  = $q.all([]);

      promise.success = function (fn) {
        promise.then(function (response) {
          fn(response.data, response.status, response.headers, {});
        });
        return promise;
      };

      promise.error = function (fn) {
        promise.then(null, function (response) {
          fn(response.data, response.status, response.headers, {});
        });
        return promise;
      };

      return promise;
    };

    return new ModelHelper();
  }])

  .factory('ModelCollection', ['$http', 'ModelHelper', '$q', function ($http, ModelHelper, $q) {

    /**
     * Constructor
     * @param data
     * @constructor
     */
    function ModelCollection (data) {
      data = data || [];

      this.$model = undefined;
      this.$promise = ModelHelper.createDummyPromise();
      this.$original = {};
      this.length = 0;

      this.hydrate(data);
    }

    inherit(ModelCollection, Array);

    /**
     * States
     * @type {string}
     */
    ModelCollection.$LOADING = 'loading';
    ModelCollection.$SUCCESS = 'success';
    ModelCollection.$ERROR = 'error';
    ModelCollection.$state = null;

    ModelCollection.prototype.toJSON = function () {
      var a = [];
      angular.forEach(this, function (item) {
        a.push(item);
      });
      return a;
    };

    /**
     * Hydrate
     * @param data
     */
    ModelCollection.prototype.hydrate = function (data) {
      data = data || {data: []};
      var self = this;
      var list = data.list ? data.list : (data.data ? data.data : data);

      this.$original = data;

      self.empty();

      angular.forEach(list, function (item, i) {
        self.push(new self.$model(item));
      });

      if (this.afterHydrate) {
        this.afterHydrate();
      }
    };


    ModelCollection.prototype.empty = function () {
      while (this.length > 0) {
        this.pop();
      }
    };

    /**
     * Is Loading?
     * @param model
     * @returns {boolean}
     */
    ModelCollection.prototype.isLoading = function (model) {
      return this.$state === ModelCollection.$LOADING;
    };

    /**
     * Configure
     * @param config
     */
    ModelCollection.prototype.configure = function (config) {
      this.$config = config;
    };

    /**
     * Load models
     * @param query
     * @returns {ModelCollection}
     */
    ModelCollection.prototype.get = function (_query) {
      var self = this;
      var url = this.$config.url;

      var query = angular.copy(_query);

      var matches = url.match(/:\w+/g);
      console.log('matches', matches);
      if (matches) {
        angular.forEach(matches, function (word) {
          var subword = word.substr(1);
          if (query[subword]) {
            url = url.replace(word, query[subword]);
            delete query[subword];
          }
        });
      }

      this.$state = ModelCollection.$LOADING;

      if (query) {
        url += '?' + ModelHelper.queryString(query);
      }

      this.$promise = $http.get(url, {cache: this.$config.cache}).then(function (resp) {
        self.$state = ModelCollection.$SUCCESS;

        if (!resp.data) {
          console.warn('Empty response');
          return;
        }

        self.hydrate(resp.data);

        self.$meta = resp.data._meta;

      }, function () {
        self.$state = ModelCollection.$ERROR;
      });

      return this;
    };

    /**
     * Is Collection Dirty
     * @returns {boolean}
     */
    ModelCollection.prototype.isDirty = function () {
      var isDirty = false;

      angular.forEach(this, function (item) {
        if (item.isDirty()) {
          isDirty = true;
        }
      });

      return isDirty;
    };

    /**
     * Add new
     */
    ModelCollection.prototype.addNew = function () {
      var model = new this.$model();
      this.push(model);
      model.$edit = true;
    };

    /**
     * Remove
     * @param item
     */
    ModelCollection.prototype.remove = function (item) {
      var self = this;
      angular.forEach(this, function (_item, i) {
        if (item === _item) {
          self.splice(i, 1);
        }
      });
    };

    ModelCollection.prototype.findByKey = function (key, value) {
      var item = [];
      angular.forEach(this, function (_item) {
        if (_item[key] === value) {
          item.push(_item);
        }
      });
      return item;
    };

    return ModelCollection;
  }]);


