angular.module('genesisApp')
  .controller('AdminUserEditController', function ($scope, $rootScope, User, $stateParams, $state, $location, OptionsService, USER_TITLES, ACCESS_LEVELS) {
    console.log("AdminUserEditController...");

    $scope.item = $stateParams.id ? (new User).find($stateParams.id) : (new User);
    $scope.password = null;

    $scope.rules = {};
    $scope.isPasswordChange = false;

    $scope.tabMemberDetails = {};

    $scope.datepickerOptions = {
      locale: {format: 'MMMM D, YYYY'},
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };

    $scope.titles = {
      options: USER_TITLES
    };

    $scope.accessLevels = {
      options: ACCESS_LEVELS
    };

    $scope.companies = {
      options: []
    };

    $scope.newsletterTypes = {
      options: []
    };

    $scope.changePassword = function () {
      $scope.isPasswordChange = !$scope.isPasswordChange;
      return false;
    };

    $scope.generatePassword = function () {
      $scope.password = $scope.randomPassword(6);
      $scope.item.password = $scope.password;
      $scope.item.password_confirmation = $scope.password;

      console.log($scope.password + "<<<<>>>>");
    };

    $scope.randomPassword = function (length) {
      var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
      var pass = "";
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      return pass;
    };

    $scope.cancel = function () {
      $location.path('/admin/users');
    };

    $scope.save = function (item) {
      item.save().then(function () {
        $rootScope.addOnlyAlert("success", "Member saved successfully.");
        $state.go('root.admin.users.edit', item);
        $scope.$parent.loadData();

      }, function (resp) {
        $scope.rules = resp.data;
        $rootScope.addOnlyAlert("danger", "Failed to save member");

      });
    };

    $scope.destroy = function (item) {
      if (confirm("Are you sure you want to delete: " + item.first_name + '?')) {
        item.destroy().then(function () {
          $rootScope.addAlert('success', 'Member successfully deleted');
          $state.go('root.admin.users');
        }, function (data) {
          $rootScope.addAlert('error', 'Error deleting user');
        });
      }
    };

    OptionsService.companies().then(function (options) {
      $scope.companies.options = options.data;
      $scope.companies.options.push({id: 0, name: "Other"});
    });

    OptionsService.newsletterTypes().then(function (options) {
      $scope.newsletterTypes.options = options.data;
    });


  });

