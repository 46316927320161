angular.module('genesisApp')
  .controller('ReportsController', function ($scope, $http, $location, $httpParamSerializer, $state) {

  })
  .controller('ReportsViewController', function ($scope, $http, $location, $httpParamSerializer, $state, $stateParams, $uibModal) {
    console.log('ReportsController...');
    console.log('$location.url()', $location.url());

    $scope.openIntervalMenu = openIntervalMenu;
    $scope.query = $location.search();

    loadData();

    //$httpParamSerializer(params);
    function loadData () {
      var apiQuery = angular.copy($stateParams);
      apiQuery.id = $state.params.uuid;
      apiQuery.compile = true;

      var apiQueryStr = $httpParamSerializer(apiQuery);

      $scope.loading = true;

      $http.get('/api/reports/' + $state.params.uuid + '?' + apiQueryStr).then(function (resp) {
        $scope.report = resp.data;

        $http.get('/api/reports/date-menus' + '?' + apiQueryStr).then(function (resp) {
          $scope.loading = false;
          $scope.reportMenu = resp.data;
        });
      });

    }

    $scope.$on('$locationChangeSuccess', function () {
      console.log('$locationChangeSuccess...');
      loadData();
    });

    function openIntervalMenu (colIndex) {
      //$scope.report.template.columns[colIndex];

      console.log('openIntervalMenu...', colIndex);

      var idx = colIndex.match(/__col_(\d+)__/)[1];

      var modalInstance = $uibModal.open({
        templateUrl: 'reports/reports-interval-modal.html',
        controller: 'ReportsIntervalModalController',
        size: 'sm',
        //appendTo: parentElem,
        resolve: {
          $parentScope: function () {
            return $scope;
          },
          colIndex: function () {
            return idx;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        //loadPreview();
        //$ctrl.selected = selectedItem;
      }, function () {
        //$log.info('Modal dismissed at: ' + new Date());
      });

    }

  })
  .controller('ReportsSidebarController', function ($scope, $rootScope, $http, $location, $state) {
    console.log('ReportsSidebarController...');
    $scope.currentId = $state.params.uuid;
    $scope.query = $location.search();

    $scope.menus = {};
    $scope.loading = true;

    if ($rootScope.currentUser.id) {
      $http.get('/api/reports/menus').then(function (resp) {
        $scope.loading = false;
        $scope.menus = resp.data;
        //console.log('$scope.menus', $scope.menus);
        recursiveExpand($scope.menus.ba);
      });

    }

    $scope.refreshToggle = true;


    $rootScope.$on('$locationChangeSuccess', function () {
      console.log('ReportsSidebarController $locationChangeSuccess...');
      $scope.query = $location.search();
    });


    if ($rootScope.currentUser.id) {

      $http.get('/api/reports/custom').then(function (resp) {
        $scope.customReports = resp.data;
      });

    }
    function recursiveExpand (menu) {
      //console.log('menu.children', menu.children);
      if (menu.children && menu.children.length) {
        angular.forEach(menu.children, function (child) {
          child.parent = menu;
          child.expanded = false;

          recursiveExpand(child);

          if (child.uuid === $scope.currentId && $scope.currentId) {
            child.expanded = true;
            recursiveParentExpand(child.parent);
          }
        });
      }
    }

    function recursiveParentExpand (parent) {
      parent.expanded = true;
      if (parent.parent) {
        recursiveParentExpand(parent.parent);
      }
    }

  })
  .controller('ReportsIntervalModalController', function ($uibModalInstance, $scope, $parentScope, colIndex, $location) {
    var query = $location.search();
    $scope.columnIndex = colIndex;
    $scope.date = query.date;
    $scope.institution = query.institution;
    $scope.settings = {
      intervalType: ''
    };

    $scope.close = function () {
      $uibModalInstance.close();
    };

    $scope.$on('$locationChangeSuccess', function () {
      $uibModalInstance.close();
    });


  }).directive('compileData', function ($compile) {
  return {
    scope: true,
    link: function (scope, element, attrs) {

      var elmnt;

      attrs.$observe('template', function (myTemplate) {
        if (angular.isDefined(myTemplate)) {
          // compile the provided template against the current scope
          elmnt = $compile(myTemplate)(scope);

          element.html(""); // dummy "clear"

          element.append(elmnt);
        }
      });
    }
  };
});




