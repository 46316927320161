angular.module('genesisApp')
  .controller('AdminNewsletterScheduleViewController', function ($scope, $state, $rootScope, NewsletterSchedule, $stateParams, $location, NewsletterScheduleRecipients) {
    console.log("AdminNewsletterScheduleViewController...", $stateParams);

    $scope.query = $location.search();
    $scope.query.id = $stateParams.id;
    $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;

    $scope.item = (new NewsletterSchedule);
    $scope.recipients = (new NewsletterScheduleRecipients);

    $scope.item.$promise.then(function () {
      console.log("$scope.item", $scope.item);

    });

    $scope.recipients.$promise.then(function () {
      console.log("$scope.recipients", $scope.recipients);
    });

    $scope.updateQuery = function () {
      $location.search($scope.query);
      loadData();
    };

    function loadData () {
      $scope.item.find($stateParams.id);
      $scope.recipients.get($scope.query);
    }

    loadData();

  });

