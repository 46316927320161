angular.module('genesisApp')
.factory('Subscribers', function (ModelCollection, Subscriber) {
    function Subscribers() {
        this.$model = Subscriber;
        this.configure({url: 'api/subscribers'});
    }

    inherit(Subscribers, ModelCollection);
    return Subscribers;
})
.factory('Subscriber', function (Model) {
    function Subscriber(data) {
        this.hydrate(data);
        this.configure({url: 'api/subscribers/:id'});
    }

    inherit(Subscriber, Model);
    return Subscriber;
});

