angular.module('genesisApp')

  .directive('statementsBlock', function (Statements) {
    return {
      restrict: 'AE',
      templateUrl: 'statements-block.html',
      scope: {
        type: '@',
        bank: '=',
        heading: '@',
        limit: '@'
      },
      link: function ($scope, element, $attrs) {
        console.log('statementsBlock...');

        $scope.limit = $scope.limit || 5;

        $scope.loadData = loadData;

        loadData();

        function loadData () {
          $scope.loadingData = true;


          $scope.statements = (new Statements);
          $scope.statements.get({
            bank_id: $scope.bank.id,
            statement_type: $scope.type,
            limit: $scope.limit
          });

          $scope.statements.$promise.then(function () {
            $scope.loadingData = false;
          });

          //ArticleService.get(params).then(function (response) {
          //  $scope.articles = response.data;
          //  console.log($scope.articles);
          //
          //  $scope.loadingData = false;
          //});
        }
      }
    };
  });

