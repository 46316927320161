angular.module('genesisApp')
    .controller('LinksController', ['$scope', '$rootScope', 'Banks', '$location',
      function ($scope, $rootScope, Banks, $location) {
        console.log('LinksController...');

        $scope.query = $location.search();
        $scope.query.has_link = true;
        $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
        $scope.loadData = loadData;
        $scope.updateQuery = updateQuery;
        $scope.search = search;
        $scope.clearSearch = clearSearch;
        $scope.list = new Banks;

        loadData();

        function loadData() {
          $scope.list.get($scope.query);
          $scope.list.$promise.then(function () {
          });
        }

        function search() {
          $scope.query.page = 1;
          $scope.updateQuery();
        }

        function clearSearch() {
          $scope.query = {};
          $scope.search();
        }

        function updateQuery() {
          $location.search($scope.query);
          loadData();
        }
      }])
    .controller('LinksSidebarController', function () {
      console.log('LinksSidebarController...');
    });

