angular.module('genesisApp')
.controller('AdminBanksController', ['$scope', '$rootScope', 'Banks', '$location', '$state',
    function ($scope, $rootScope, Banks, $location, $state) {
        console.log('AdminBanksController...');

        $scope.query = $location.search();
        $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
        $scope.loadData = loadData;
        $scope.updateQuery = updateQuery;
        $scope.search = search;
        $scope.clearSearch = clearSearch;
        $scope.destroy = destroy;
        $scope.list = new Banks;

        loadData();

        function loadData() {
            $scope.list.get($scope.query);
            $scope.list.$promise.then(function () {
            });
        }

        function search() {
            $scope.query.page = 1;
            $scope.updateQuery();
        }

        function clearSearch() {
            $scope.query = {};
            $scope.search();
        }

        function updateQuery() {
            $location.search($scope.query);
            loadData();
        }

        $scope.edit = function (bankId) {
            $state.go('root.admin.bank.edit', {id: bankId});
        };

        function destroy(item) {
            if (confirm("Are you sure you want to delete: " + item.name + '?')) {
                item.destroy().then(function () {
                    $rootScope.addAlert('success', 'Bank deleted');
                    loadData();
                }, function (data) {
                    $rootScope.addAlert('error', 'Error deleting bank');
                });
            }
        }
    }]);    

