angular.module('genesisApp')
    .factory('ArticleService', ['$http', function ($http) {
        var articleService = {};

        articleService.get = function (params) {
            return $http.get('/api/articles', {params: params});
        };

        return articleService;
    }]);

