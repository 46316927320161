angular.module('genesisApp')
  .controller('DashboardController', function ($scope, Articles, $http, $q) {
    console.log('DashboardController...');


    $scope.cmsData = null;
    $scope.cmsDataLoading = true;

    $http.get('/api/cms-content').then(function (resp) {
      $scope.cmsData = resp.data;
      $scope.cmsDataLoading = false;
      console.log('$scope.cmsData', $scope.cmsData);
    });

    $scope.slides = [
      {index: 0, image: '/images/slide-1.jpg', position: 'bottom'},
      {index: 1, image: '/images/slide-2.jpg'},
      {index: 2, image: '/images/slide-3.jpg'},
      {index: 3, image: '/images/slide-4.jpg'}
    ];

    $scope.aboutPeople = [
      {name: "Richard Ketley", title: "Managing Partner", email: "richardk@genesis-analytics.com", image: "/images/richard.jpg"},
      {name: "Bavani Naidoo", title: "Partner", email: "bavanin@genesis-analytics.com", image: "/images/bavani.jpg"},
      {name: "James Bernstein", title: "Risk Management", email: "jamesb@genesis-analytics.com", image: "/images/jamesb.jpg"},
      {name: "Shezaad Sunderji", title: "Principal (Financial Services | East Africa)", email: "", image: "/images/shezaad_2.jpg"},
      {name: "Tascha Terblanche", title: "Manager (Financial Services | West Africa)", email: "", image: "/images/tascha.jpg"},
      {name: "Tom Magara", title: "Manager (Financial Services | East Africa)", email: "", image: "/images/Tom_FS_28229.jpg"},
    ];

    $scope.articles = [];

    function loadArticles () {
      $scope.loadingData = true;

      var getLatestPromise = $http.get('/api/reports/latest');
      var getArticlesPromise = $http.get('/api/articles/latest');

      var promises = [
        getArticlesPromise,
        getLatestPromise
      ];

      getLatestPromise.then(function (resp) {
        $scope.latestReport = resp.data;
      });

      getArticlesPromise.then(function (resp) {
        $scope.articles = resp.data;
      });

      $q.all(promises).then(function () {
        $scope.loadingData = false;

        var summary  = 'Latest ' + $scope.latestReport.series + ' data released in ' + $scope.latestReport.monthFormatted + ' ' + $scope.latestReport.year;

        if ($scope.latestReport.banks > 1) {
          summary += ' from ' + $scope.latestReport.banks + ' banks';
        }

        summary += '.';

        $scope.articles.unshift({
          heading: "Latest " + $scope.latestReport.series + ' data: ' + $scope.latestReport.monthFormatted + ' ' + $scope.latestReport.year,
          body_summary: summary,
          hyperlink: '/#/reports'
        });

        angular.forEach($scope.articles, function (article, i) {
          angular.extend($scope.slides[i], article);
        });

      });

    }

    loadArticles();


  })
;

