angular.module('genesisApp')
    .factory('Dataset', ['$http', function ($http) {
        var Dataset = {};

        Dataset.get = function (params) {
            return $http.get('/api/reservebank/datasets', params);
        };

        return Dataset;
    }]);

