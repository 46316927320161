angular.module('genesisApp')
  .controller('NewsSectionController', function ($scope, $rootScope, $stateParams, Articles, $location, ARTICLE_TYPES) {
    console.log('NewsSectionController....');

    $scope.default_slug = 'south-africa';
    $scope.country_slug = $rootScope.default_slug ? $rootScope.country_slug : $scope.country_slug;
    $scope.article_type = $stateParams.article_type;
    $scope.query = $location.search();
    $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;

    $scope.country_slug = $stateParams.country_slug ? $stateParams.country_slug : $scope.default_slug;

    $rootScope.country_slug = $scope.country_slug;
    $rootScope.article_type = $scope.article_type;

    console.log("Article section: " + $stateParams.article_type);
    console.log("Country slug: " + $scope.country_slug);

    angular.forEach(ARTICLE_TYPES, function (articleType) {
      if (articleType.id == $scope.article_type) {
        $scope.section = articleType;
      }
    });

    $scope.loadData = loadData;
    $scope.updateQuery = updateQuery;
    $scope.search = search;
    $scope.clearSearch = clearSearch;
    $scope.list = new Articles;

    if ($scope.query.article_type) {
      $scope.query.article_type = parseInt($scope.query.article_type);
    }

    loadData();

    function loadData () {
      $scope.loadingData = true;
      var query = $location.search();
      angular.extend(query, $stateParams);
      query.order = 'published_at';
      $scope.list.get(query);
      $scope.list.$promise.then(function () {
        $scope.loadingData = false;
      });
    }

    function search () {
      $scope.query.page = 1;
      $scope.updateQuery();
    }

    function clearSearch () {
      $scope.query = {};
      $scope.search();
    }

    function updateQuery () {
      var query = angular.copy($scope.query);

      if ($scope.query.startDate) {
        query.startDate = moment($scope.query.startDate).format('YYYY-MM-DD');
      }
      if ($scope.query.endDate) {
        query.endDate = moment($scope.query.endDate).format('YYYY-MM-DD');
      }

      console.log('query', query);

      $location.search(query);

      loadData();
    }
  });

