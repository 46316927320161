/**
 * Created by batanayimatuku on 2016/11/22.
 */
angular.module('genesisApp')
    .factory('NewsletterLog', ['$http', function ($http) {
        var NewsletterLog = {};

        NewsletterLog.get = function (params) {
            return $http.get('/api/newsletter/log', params);
        };

        return NewsletterLog;
    }]);

