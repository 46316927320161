angular.module('genesisApp')
  .factory('Model', ['$http', 'ModelHelper', '$q', function ($http, ModelHelper, $q) {

    function Model () {
    }

    /**
     * States
     * @type {string}
     */
    Model.$SAVING = 'saving';
    Model.$LOADING = 'loading';
    Model.$SUCCESS = 'success';
    Model.$ERROR = 'error';
    Model.$state = null;

    Model.prototype.beforeHydrate = function () {};

    /**
     * Hydrate
     * @param data
     */
    Model.prototype.hydrate = function (data) {
      data = data || {};

      this.$originalData = data;
      this.$promise = ModelHelper.createDummyPromise();

      if (this.beforeHydrate) {
        this.beforeHydrate(data);
      }

      this.$original = angular.copy(data);

      angular.extend(this, data);
    };

    Model.prototype.toJSON = function () {
      var itemClone = angular.extend({}, this);
      angular.forEach(itemClone, function (val, key) {
        if (key.charAt(0) === '$' || key.charAt(0) === '_') {
          delete itemClone[key];
        }
      });
      return itemClone;
    };


    /**
     * Configure
     * @param config
     */
    Model.prototype.configure = function (config) {
      this.$config = config;
    };

    /**
     * Resource update URL
     * @returns {*}
     */
    Model.prototype.resourceUpdateURL = function () {
      var self = this;
      var primaryKey = this.$config.primaryKey ? this.$config.primaryKey : 'id';

      if (! this[primaryKey]) {
        throw new Error('Calling resourceUpdateURL of uninitialized entity');
      }

      var url = this.$config.url;

      var matches = url.match(/:[\$\w]+/g);

      if (matches) {
        angular.forEach(matches, function (key) {
          url = url.replace(key, self[key.substr(1)]);
        });
      }

      return url;
    };

    /**
     * Resource Create URL
     * @returns {*}
     */
    Model.prototype.resourceCreateURL = function () {
      var self = this;
      var url = this.$config.url;
      var primaryKey = this.$config.primaryKey ? this.$config.primaryKey : 'id';

      url = url.replace('/:' + primaryKey, '');

      var matches = url.match(/:[\$\w]+/g);

      if (matches) {
        angular.forEach(matches, function (key) {
          url = url.replace(key, self[key.substr(1)]);
        });
      }

      return url;
    };

    /**
     * Find
     * @param id
     * @param query
     * @returns {Model}
     */
    Model.prototype.find = function (id, query) {
      var self = this;
      var primaryKey = this.$config.primaryKey ? this.$config.primaryKey : 'id';
      var url = this.$config.url.replace(':' + primaryKey, id);

      if (query) {
        url += '?' + ModelHelper.queryString(query);
      }

      self.$state = Model.$LOADING;

      this.$promise = $http.get(url);

      this.$promise.then(function (response) {
        self.hydrate(response.data);
        self.$state = Model.$SUCCESS;
      });

      return this;
    };

    /**
     * Data
     * @returns {{}}
     */
    Model.prototype.getData = function () {
      var data = {};

      angular.forEach(this, function (value, key) {
        if (key.charAt(0) !== '$' || key.charAt(0) === '_') {
          data[key] = value;
        }
      });

      return data;
    };

    /**
     * Save
     * @returns {*}
     */
    Model.prototype.save = function () {
      var self = this;

      var promise;
      var data = this.getData();
      var primaryKey = this.$config.primaryKey ? this.$config.primaryKey : 'id';

      this.beforeSave(data);

      if (this[primaryKey]) {
        promise = $http.put(this.resourceUpdateURL(), data);
      } else {
        promise = $http.post(this.resourceCreateURL(), data);
      }

      this.$state = Model.$SAVING;

      promise.then(function (resp) {
        self.hydrate(resp.data);
        self.$state = Model.$SUCCESS;
      }, function (resp) {
        self.$state = Model.$ERROR;
        self.$errors = resp.data;
      });

      return promise;
    };

    Model.prototype.beforeSave = function (data) {
      return data;
    };

    /**
     * Is Dirty?
     * @returns {boolean}
     */
    Model.prototype.isDirty = function () {
      return !angular.equals(this, this.$original);
    };

    /**
     * Revert to original state
     * @returns {*}
     */
    Model.prototype.revert = function () {
      return this.hydrate(this.$originalData);
    };

    /**
     * Delete this
     * @returns {*}
     */
    Model.prototype.destroy = function () {
      return $http({url: this.resourceUpdateURL(), method: 'DELETE'});
    };

    /**
     * Is Loading?
     * @param model
     * @returns {boolean}
     */
    Model.prototype.isLoading = function (model) {
      return this.$state === Model.$LOADING;
    };

    /**
     * Is Loading?
     * @param model
     * @returns {boolean}
     */
    Model.prototype.isSaving = function (model) {
      return this.$state === Model.$SAVING;
    };

    Model.prototype.exists = function () {
      var primaryKey = this.$config.primaryKey ? this.$config.primaryKey : 'id';
      return !!this[primaryKey];
    };

    return Model;
  }]);


