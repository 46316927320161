angular.module('genesisApp')
    .controller('AdminRBDatasetsController', ['$scope', '$rootScope', 'Dataset', 'Upload', 'JobXml',
        function ($scope, $rootScope, Dataset, Upload, JobXml) {
            console.log('AdminDatasetsController...');

            $scope.loadData = loadData;
            $scope.list =  null;
            $scope.loadingData = true;
            $scope.loadingJobs = true;
            $scope.uploadingFile = false;

            loadData();

            function loadData() {
                Dataset.get().then(function (response) {
                    $scope.loadingData = false;
                    $scope.list = response.data;
                });
            }

            $scope.isLoading = function (){
                return $scope.loadingData;
            };

            $scope.uploadFile = function (file, errFiles) {
                $scope.f = file;
                $scope.errFile = errFiles && errFiles[0];
                $scope.uploadingFile = true;

                Upload.upload({
                    url: '/api/reservebank/uploadXML',
                    data: {file: file}
                }).then(function (resp) {
                    $scope.uploadingFile = false;
                    $rootScope.addAlert('success', 'File successfully uploaded.');
                    $rootScope.$broadcast('rbJobsAdded', {});
                }, function (resp) {
                    $scope.uploadingFile = false;
                    $rootScope.addAlert('error', 'Error uploading file.');
                }, function (evt) {
                });
            };
        }]);

