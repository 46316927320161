angular.module('genesisApp')
  .controller('ModalNewsletterArticlesController', function ($scope, Articles, $rootScope, OptionsService, $location, $uibModalInstance) {
    $scope.group = $rootScope.currentGroup;
    $scope.query = {};
    $scope.query.page = 1;
    $scope.loadData = loadData;
    $scope.updateQuery = updateQuery;
    $scope.search = search;
    $scope.clearSearch = clearSearch;
    $scope.list = new Articles;
    $scope.articles = [];
    $scope.articleIds = [];

    $scope.datePicker = {
      dates: {
        startDate: null,
        endDate: null
      }
    };

    $scope.fields = [
      {
        label: 'Heading',
        value: 'heading'
      },
      {
        label: 'Hyperlink',
        value: 'hyperling'
      },
      {
        label: 'Body',
        value: 'body'
      }
    ];

    $scope.sources = {
      options: []
    };

    $scope.countries = {
      options: []
    };

    OptionsService.sources().then(function (options) {
      $scope.sources.options = options.data;
    });

    OptionsService.countries().then(function (options) {
      $scope.countries.options = options.data;
    });

    loadData();

    function loadData () {
      $scope.loadingData = true;
      $scope.list.get($scope.query);
      $scope.list.$promise.then(function (data) {
        angular.forEach($scope.list, function (article, key) {
          if ($rootScope.groupArticles.indexOf(article.id) >= 0) {
            $scope.articleIds[article.id] = key;
          }
        });

        $scope.loadingData = false;
      });
    }

    function search () {
      $scope.query.page = 1;
      $scope.updateQuery();
    }

    function clearSearch () {
      $scope.query = {};
      $scope.search();
    }

    function updateQuery () {
      //$location.search($scope.query);

      if ($scope.query.send_at) {
        $scope.query.send_at = moment($scope.query.send_at).format('YYYY-MM-DD');
      }

      loadData();

      console.log("loading cool data");
    }

    $scope.save = function (articleIds) {
      $scope.articles = {};

      console.log(">>>>");
      console.log(articleIds);

      angular.forEach(articleIds, function (value, key) {
        if (value >= 0) {
          $scope.articleIds[key] = value;
          $scope.articles[key] = $scope.list[value].$original;
        } else if (value < 0) {
          $scope.articleIds.splice(key, 1);
        }
      });

      $rootScope.articles = $scope.articles;
      $uibModalInstance.dismiss('cancel');
    };

    $scope.items = [];
    $scope.ok = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

