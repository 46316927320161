angular.module('genesisApp')
.constant('BANK_GROUPS',
    [
        {
            id: 1,
            name: 'Big Four Banks'
        },
        {
            id: 2,
            name: 'Other SA Banks'
        },
        {
            id: 3,
            name: 'Foreign Banks'
        },
        {
            id: 4,
            name: 'Discontinued Banks'
        }
    ]
);

