angular.module('genesisApp')
  .controller('AdminUsersController', function ($scope, $rootScope, Users, $location, $state, OptionsService, $uibModal, $http) {
    console.log("AdminUsersController...");

    $scope.query = $location.search();
    $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
    $scope.loadData = loadData;
    $scope.updateQuery = updateQuery;
    $scope.search = search;
    $scope.clearSearch = clearSearch;
    $scope.destroy = destroy;
    $scope.list = new Users;
    $scope.export = {
      data: []
    };

    $scope.fields = [
      {
        label: 'Email',
        value: 'email'
      },
      {
        label: 'First Name',
        value: 'first_name'
      },
      {
        label: 'Last Name',
        value: 'last_name'
      },
      {
        label: 'Company',
        value: 'company'
      }
    ];

    loadData();

    $scope.companies = {
      options: []
    };

    OptionsService.companies().then(function (options) {
      $scope.companies.options = options.data;
    });

    function loadData () {
      $scope.list.get($scope.query);
      //$scope.list.$promise.then(function (response) {
      //    $scope.export = response.data;
      //});
    }

    function search () {
      $scope.query.page = 1;
      $scope.updateQuery();
    }

    function clearSearch () {
      $scope.query = {};
      $scope.search();
    }

    function updateQuery () {
      $location.search($scope.query);
      loadData();
    }

    $scope.edit = function (userId) {
      $state.go('root.admin.user.edit', {id: userId});
    };

    function destroy (item) {
      if (confirm("Are you sure you want to delete: " + item.first_name + '?')) {
        item.destroy().then(function () {
          $rootScope.addAlert('success', 'Member successfully deleted');
          loadData();
        }, function (data) {
          $rootScope.addAlert('error', 'Error deleting user');
        });
      }
    }


    $scope.addSubscription = function (user) {
      console.log('$scope.addSubscription...');
      var uibModalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'admin/modal/add-subscription.html',
        controller: 'ModalAddSubscriptionController',
        controllerAs: '$modal',
        size: 'lg',
        resolve: {
          $user: user,
          $parentScope: $scope
        }
      });

      uibModalInstance.result.then(function (result) {
        console.log('ModalAddSubscriptionController uibModalInstance.result', result);

        if (user.id) {
          $http.put('/api/users/' + user.id + '/subscription', result).then(function (resp) {
            user.hydrate(resp.data);
          }, function (resp) {

          });
        }

      }, function () {
      });


    };

    $scope.saveUser = function (user) {
      user.save().then(function () {
        },
        function (resp) {
          $rootScope.addAlert('error', 'Error saving user');
        });
    };


  })
  .controller('ModalAddSubscriptionController', function ($uibModal, $scope, $rootScope, User, $stateParams, $state, $location, $parentScope, Subscriptions, Subscription, $uibModalInstance, $user) {
    $scope.search = search;
    $scope.subscriptions = new Subscriptions;
    $scope.subscriptions.get();

    $scope.query = {};

    function search () {
      $scope.query.page = 1;
      $scope.subscriptions.get($scope.query);
    }

    $scope.select = function (subscription) {
      console.log('$scope.select...');

      $user.subscription = subscription;

      $uibModalInstance.close(subscription);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

  });


