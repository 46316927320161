angular.module('genesisApp')
  .directive("spinner", function () {
    return {
      restrict: "E",
      template: '<i class="fa fa-refresh fa-spin"></i>',
      scope: {},
      link: function ($scope) {
      }
    };
  })
;

