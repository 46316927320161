angular.module('genesisApp')
  .factory('Users', function (ModelCollection, User) {
    function Users (data) {
      this.$model = User;
      this.configure({url: 'api/users'});

      if (data) {
        this.hydrate(data);
      }
    }

    inherit(Users, ModelCollection);

    return Users;
  })
  .factory('User', function (Model, $http) {
    function User (data) {
      this.hydrate(data);
      this.configure({url: 'api/users/:id'});
    }

    inherit(User, Model);

    //User.prototype.syncAccess = function () {
    //  var promise = $http.put(this.resourceUpdateURL(), {
    //
    //  });
    //};

    return User;
  })
  .factory('CurrentUser', function (Model) {

    function CurrentUser (data) {
      this.hydrate(window.user || data);
      this.configure({url: 'api/users/profile'});
    }

    //inherit(User, Model);

    inherit(CurrentUser, Model);
    return CurrentUser;
  });

