angular.module('genesisApp')
  .controller('AdminNewsFlashEditController', function ($scope, $state, $rootScope, NewsFlash, $stateParams, $location) {
    $scope.item = $stateParams.id ? (new NewsFlash).find($stateParams.id) : (new NewsFlash);

    console.log("news flash");
    console.log($scope.item);

    $scope.rules = {};

    $scope.cancel = function () {
      $location.path('/admin/newsflashes');
    };

    $scope.save = function (item) {
      item.save().then(function () {
        $scope.rules = {};
        $rootScope.addOnlyAlert("success", "News flash saved successfully");
        $state.go('root.admin.newsflashes.edit', item);
        $scope.$parent.loadData();
      }, function (resp) {
        $scope.rules = resp.data;
        $rootScope.addOnlyAlert("danger", "Failed to save news flash");
      });
    };
  });

