angular.module('genesisApp')
  .config(['$stateProvider', "$breadcrumbProvider", "$urlRouterProvider", function ($stateProvider, $breadcrumbProvider, $urlRouterProvider) {
    var navAdminView = 'nav-admin.html';
    var sidebarAdminView = 'sidebar-admin.html';
    var navFrontendView = 'nav-frontend.html';
    var sidebarFrontendView = 'sidebar-frontend.html';
    var redirectToDefault = 'root.login';
    var defaultMemberPermissions = {
      //only: 'USER'
      //,
      //redirectTo: redirectToDefault
    };

    function viewsFrontend (main, overrides) {
      var opts = {
        "nav@": {
          templateUrl: 'nav-frontend.html'
        },
        "sidebar": {
          templateUrl: 'sidebar-frontend.html'
        },
        "main": main
      };

      angular.extend(opts, overrides);

      //console.log('opts', opts);

      return opts;
    }

    function viewsAdmin (main) {
      return {
        "layout": {
          templateUrl: 'layout-sidebar.html'
        },
        "nav@": {
          templateUrl: 'nav-admin.html'
        },
        "sidebar": {
          templateUrl: 'sidebar-admin.html'
        },
        "main": main
      }
    }

    $breadcrumbProvider.setOptions({
      prefixStateName: 'Home'
    });

    $stateProvider
      .state('root', {
        url: "/",
        abstract: true,
        ncyBreadcrumb: {
          label: 'Root'
        },
        views: {
          layout: {
            templateUrl: 'layout-sidebar.html'
          }
        }
      })
      .state('root.home', {
        url: "home",
        ncyBreadcrumb: {
          label: 'Home'
        },
        views: {
          "nav@": {
            templateUrl: "nav-frontend.html"
          },
          "main@root.home": {
            templateUrl: "home.html",
            controller: "DashboardController"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })
      .state('root.about', {
        url: "about",
        ncyBreadcrumb: {
          label: 'About'
        },
        views: {
          "nav@": {
            templateUrl: "nav-frontend.html"
          },
          "main@root.about": {
            templateUrl: "about.html",
            controller: "PageController"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })

      .state('root.login', {
        url: "login",
        ncyBreadcrumb: {
          label: 'Login'
        },
        views: {
          "nav@": {
            templateUrl: "nav-frontend.html"
          },
          "main@root.login": {
            templateUrl: "auth/login.html",
            controller: "AuthController"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })

      .state('root.profile', {
        url: "profile",
        ncyBreadcrumb: {
          label: 'Profile'
        },
        data: {
          permissions: {
            only: ['USER'],
            redirectTo: redirectToDefault
          }
        },
        views: viewsFrontend({
          templateUrl: "profile.html",
          controller: "ProfileController"
        }, {
          sidebar: {
            controller: "ProfileSidebarController",
            templateUrl: "sidebar-profile.html"
          }
        })
      })
      .state('root.admin', {
        url: "admin",
        ncyBreadcrumb: {
          label: 'Admin'
        },
        data: {
          permissions: {
            only: ['ADMIN'],
            redirectTo: redirectToDefault
          }
        },
        views: viewsAdmin({
          templateUrl: "admin/dashboard.html",
          controller: "AdminDashboardController"
        })
      })
      .state('root.admin.users', {
        url: "/users",
        views: viewsAdmin({
          templateUrl: "admin/users.html",
          controller: "AdminUsersController"
        }),
        ncyBreadcrumb: {
          label: 'Users'
        }
      })
      .state('root.admin.users.edit', {
        url: "/:id/edit",
        views: viewsAdmin({
          templateUrl: "admin/user-edit.html",
          controller: "AdminUserEditController"
        }),
        ncyBreadcrumb: {label: 'Edit'}
      })
      .state('root.admin.users.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/user-edit.html",
          controller: "AdminUserEditController"
        })
      })
      .state('root.admin.subscriptions', {
        url: "/subscriptions",
        views: viewsAdmin({
          templateUrl: "admin/subscriptions.html",
          controller: "AdminSubscriptionsController"
        }),
        ncyBreadcrumb: {
          label: 'Subscriptions'
        }
      })
      .state('root.admin.subscriptions.create', {
        url: "/create",
        views: viewsAdmin({
          templateUrl: "admin/subscription-edit.html",
          controller: "AdminSubscriptionEditController"
        }),
        ncyBreadcrumb: {label: 'Create'}
      })
      .state('root.admin.subscriptions.edit', {
        url: "/:id/edit",
        views: viewsAdmin({
          templateUrl: "admin/subscription-edit.html",
          controller: "AdminSubscriptionEditController"
        }),
        ncyBreadcrumb: {label: 'Edit'}
      })
      .state('root.admin.companies', {
        url: "/companies",
        ncyBreadcrumb: {
          label: 'Companies'
        },
        views: viewsAdmin({
          templateUrl: "admin/companies.html",
          controller: "AdminCompaniesController"
        })
      })
      .state('root.admin.companies.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/company-edit.html",
          controller: "AdminCompanyEditController"
        })
      })
      .state('root.admin.companies.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/company-edit.html",
          controller: "AdminCompanyEditController"
        })
      })
      .state('root.admin.articles', {
        url: "/articles",
        ncyBreadcrumb: {
          label: 'Articles'
        },
        views: viewsAdmin({
          templateUrl: "admin/articles.html",
          controller: "AdminArticlesController"
        })
      })
      .state('root.admin.articles.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/article-edit.html",
          controller: "AdminArticleEditController"
        })
      })
      .state('root.admin.articles.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/article-edit.html",
          controller: "AdminArticleEditController"
        })
      })
      .state('root.admin.sources', {
        url: "/sources",
        ncyBreadcrumb: {
          label: 'Sources'
        },
        views: viewsAdmin({
          templateUrl: "admin/sources.html",
          controller: "AdminSourcesController"
        })
      })
      .state('root.admin.sources.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/source-edit.html",
          controller: "AdminSourceEditController"
        })
      })
      .state('root.admin.sources.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/source-edit.html",
          controller: "AdminSourceEditController"
        })
      })
      .state('root.admin.countries', {
        url: "/countries",
        ncyBreadcrumb: {
          label: 'Countries'
        },
        views: viewsAdmin({
          templateUrl: "admin/countries.html",
          controller: "AdminCountriesController"
        })
      })
      .state('root.admin.countries.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/country-edit.html",
          controller: "AdminCountryEditController"
        })
      })
      .state('root.admin.countries.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/country-edit.html",
          controller: "AdminCountryEditController"
        })
      })
      .state('root.admin.newsflashes', {
        url: "/newsflashes",
        ncyBreadcrumb: {
          label: 'Newsflashes'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsflashes.html",
          controller: "AdminNewsFlashesController"
        })
      })
      .state('root.admin.newsflashes.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsflash-edit.html",
          controller: "AdminNewsFlashEditController"
        })
      })
      .state('root.admin.newsflashes.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsflash-edit.html",
          controller: "AdminNewsFlashEditController"
        })
      })
      .state('root.admin.statements', {
        url: "/statements",
        ncyBreadcrumb: {
          label: 'Statements'
        },
        views: viewsAdmin({
          templateUrl: "admin/statements.html",
          controller: "AdminStatementsController"
        })
      })
      .state('root.admin.statements.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/statement-edit.html",
          controller: "AdminStatementEditController"
        })
      })
      .state('root.admin.statements.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/statement-edit.html",
          controller: "AdminStatementEditController"
        })
      })
      .state('root.admin.newsletters', {
        url: "/newsletters",
        ncyBreadcrumb: {
          label: 'Newsletters'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsletters.html",
          controller: "AdminNewslettersController"
        })
      })
      .state('root.admin.newsletters.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsletter-edit.html",
          controller: "AdminNewsletterEditController"
        })
      })
      .state('root.admin.newsletters.preview', {
        url: "/:id/preview",
        ncyBreadcrumb: {
          label: 'Preview'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsletter-preview.html",
          controller: "AdminNewsletterPreviewController"
        })
      })
      .state('root.admin.newsletters.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsletter-edit.html",
          controller: "AdminNewsletterEditController"
        })
      })
      .state('root.admin.newsletters.newsletter-schedules', {
        url: "/newsletter-schedules/:id",
        ncyBreadcrumb: {
          label: 'View'
        },
        views: viewsAdmin({
          templateUrl: "admin/newsletter-schedule-view.html",
          controller: "AdminNewsletterScheduleViewController"
        })
      })

      .state('root.admin.banks', {
        url: "/banks",
        ncyBreadcrumb: {
          label: 'Banks'
        },
        views: viewsAdmin({
          templateUrl: "admin/banks.html",
          controller: "AdminBanksController"
        })
      })
      .state('root.admin.banks.edit', {
        url: "/:id/edit",
        ncyBreadcrumb: {
          label: 'Edit'
        },
        views: viewsAdmin({
          templateUrl: "admin/bank-edit.html",
          controller: "AdminBankEditController"
        })
      })
      .state('root.admin.banks.create', {
        url: "/create",
        ncyBreadcrumb: {
          label: 'Create'
        },
        views: viewsAdmin({
          templateUrl: "admin/bank-edit.html",
          controller: "AdminBankEditController"
        })
      })
      .state('root.register', {
        url: "register",
        ncyBreadcrumb: {
          label: 'Register'
        },
        views: {
          "nav@": {
            templateUrl: "nav-frontend.html"
          },
          "main@root.register": {
            templateUrl: "auth/register.html",
            controller: "AuthController"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })
      .state('root.find-account', {
        url: "find-account",
        ncyBreadcrumb: {
          label: 'Find account'
        },
        views: {
          "nav@": {
            templateUrl: 'nav-frontend.html'
          },
          //"sidebar": {
          //  templateUrl: 'sidebar-frontend.html'
          //},
          "main@root.find-account": {
            templateUrl: "auth/find-account.html",
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }

      })
      .state('root.find-account.reset', {
        url: "/reset",
        ncyBreadcrumb: {
          label: 'Find account'
        },
        views: {
          "nav@": {
            templateUrl: 'nav-frontend.html'
          },
          //"sidebar": {
          //  templateUrl: 'sidebar-frontend.html'
          //},
          "main@root.find-account.reset": {
            templateUrl: "auth/find-account-reset.html"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })
      .state('root.find-account.request', {
        url: "/request",
        ncyBreadcrumb: {
          label: 'Find account'
        },
        views: {
          "nav@": {
            templateUrl: 'nav-frontend.html'
          },
          //"sidebar": {
          //  templateUrl: 'sidebar-frontend.html'
          //},
          "main@root.find-account.request": {
            templateUrl: "auth/find-account-request.html"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })

      .state('root.forgot-password', {
        url: "forgot-password",
        ncyBreadcrumb: {
          label: 'Forgot Password'
        },
        views: {
          "nav@": {
            templateUrl: "nav-frontend.html"
          },
          "main@root.forgot-password": {
            templateUrl: "auth/recover-password.html",
            controller: "AuthController"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })

      .state('root.reset-password', {
        url: "password/reset/:token",
        ncyBreadcrumb: {
          label: 'Reset Password'
        },
        views: {
          "nav@": {
            templateUrl: "nav-frontend.html"
          },
          "main@root.reset-password": {
            templateUrl: "auth/reset-password.html",
            controller: "AuthController"
          },
          "layout@": {
            templateUrl: "layout-no-sidebar.html"
          }
        }
      })

      .state('root.reports', {
        url: "reports",
        ncyBreadcrumb: {
          label: 'Reports'
        },
        data: {
          permissions: defaultMemberPermissions
        },
        views: viewsFrontend({
          templateUrl: "reports.html",
          controller: "ReportsController"
        }, {
          sidebar: {
            controller: "ReportsSidebarController",
            templateUrl: "sidebar-reports.html"
          }
        })
      })
      .state('root.reports.view', {
        url: "/:uuid?date&institution",
        ncyBreadcrumb: {
          label: 'View Report'
        },
        views: viewsFrontend({
          templateUrl: "reports-view.html",
          controller: "ReportsViewController"
        }, {
          sidebar: {
            controller: "ReportsSidebarController",
            templateUrl: "sidebar-reports.html"
          }
        })
      })
      .state('root.reports.view.interval', {
        url: "/:uuid/interval?column&interval&intervalType",
        ncyBreadcrumb: {
          label: 'Historical'
        },
        views: viewsFrontend({
          templateUrl: "reports-view.html",
          controller: "ReportsViewController"
        }, {
          sidebar: {
            controller: "ReportsSidebarController",
            templateUrl: "sidebar-reports.html"
          }
        })
      })
      .state('root.reportsBuilder', {
        url: "reports-builder",
        ncyBreadcrumb: {
          label: 'Reports Builder'
        },
        data: {
          permissions: defaultMemberPermissions
        },
        views: viewsFrontend({
          templateUrl: "report-builder-index.html",
          controller: "ReportsBuilderController"
        }, {
          sidebar: {
            controller: "ReportsBuilderSidebarController",
            templateUrl: "reports/sidebar-report-builder.html"
          }
        })
      })
      .state('root.reportsBuilder.new', {
        url: "/new?copy",
        ncyBreadcrumb: {
          label: 'Reports Builder - Create'
        },
        data: {
          isAuth: true
        },
        views: viewsFrontend({
          templateUrl: "report-builder-edit.html",
          controller: "ReportsBuilderController"
        }, {
          sidebar: {
            controller: "ReportsBuilderSidebarController",
            templateUrl: "reports/sidebar-report-builder.html"
          }
        })
      })
      .state('root.reportsBuilder.edit', {
        url: "/:uuid/edit",
        ncyBreadcrumb: {
          label: 'Reports Builder - Edit'
        },
        data: {
          isAuth: true
        },
        views: viewsFrontend({
          templateUrl: "report-builder-edit.html",
          controller: "ReportsBuilderController"
        }, {
          sidebar: {
            controller: "ReportsBuilderSidebarController",
            templateUrl: "reports/sidebar-report-builder.html"
          }
        })
      })
      .state('root.news', {
        url: "news",
        ncyBreadcrumb: {
          label: 'News'
        },
        //data: {
          //permissions: defaultMemberPermissions
        //},
        views: viewsFrontend({
          templateUrl: "news.html",
          controller: "NewsController"
        }, {
          sidebar: {
            controller: "NewsSidebarController",
            templateUrl: "sidebar-news.html"
          }
        })
      })
      .state('root.news.section', {
        url: "/:country_slug/:article_type",
        ncyBreadcrumb: {
          label: 'Section'
        },
        data: {
          isAuth: false
        },
        views: viewsFrontend({
          templateUrl: "news-section.html",
          controller: "NewsSectionController"
        }, {
          sidebar: {
            controller: "NewsSidebarController",
            templateUrl: "sidebar-news.html"
          }
        })
      })
      .state('root.statements', {
        url: "statements?bank_id&type",
        ncyBreadcrumb: {
          label: 'Statements'
        },
        //data: {
        //  permissions: defaultMemberPermissions
        //},
        views: viewsFrontend({
          templateUrl: "statements.html",
          controller: "StatementsController"
        }, {
          sidebar: {
            controller: "StatementsController",
            templateUrl: "sidebar-statements.html"
          }
        })
      })
      .state('root.banks', {
        url: "banks",
        ncyBreadcrumb: {
          label: 'Banks'
        },
        //data: {
        //  permissions: defaultMemberPermissions
        //},
        views: viewsFrontend({
          templateUrl: "banks.html",
          controller: "BanksController"
        }, {
          sidebar: {
            controller: "BanksSidebarController",
            templateUrl: "sidebar-banks.html"
          }
        })
      })
      .state('root.banks.country', {
        url: "/:country_id",
        ncyBreadcrumb: {
          label: 'Groups'
        },
        //data: {
        //  permissions: defaultMemberPermissions
        //},
        views: viewsFrontend({
          templateUrl: "banks-country-groups.html",
          controller: "BanksCountryGroupsController"
        }, {
          sidebar: {
            controller: "BanksSidebarController",
            templateUrl: "sidebar-banks.html"
          }
        })
      })
      .state('root.banks.country.group', {
        url: "/group/:group_id",
        ncyBreadcrumb: {
          label: '{{ group.name }}'
        },
        //data: {
        //  permissions: defaultMemberPermissions
        //},
        views: viewsFrontend({
          templateUrl: "banks-group.html",
          controller: "BanksGroupController"
        }, {
          sidebar: {
            controller: "BanksSidebarController",
            templateUrl: "sidebar-banks.html"
          }
        })
      })
      .state('root.banks.country.group.info', {
        url: "/bank/:bank_id",
        ncyBreadcrumb: {
          label: '{{ bank.name }}'
        },
        //data: {
        //  permissions: defaultMemberPermissions
        //},
        views: viewsFrontend({
          templateUrl: "banks-info.html",
          controller: "BanksInfoController"
        }, {
          sidebar: {
            controller: "BanksSidebarController",
            templateUrl: "sidebar-banks.html"
          }
        })
      })
      .state('root.admin.reservebank', {
        url: "/reservebank",
        ncyBreadcrumb: {
          label: 'Reserve Bank'
        },
        views: viewsAdmin({
          templateUrl: "admin/rb-datasets.html",
          controller: "AdminRBDatasetsController"
        })
      })
      .state('root.admin.reservebank.datasets', {
        url: "/datasets",
        ncyBreadcrumb: {
          label: 'Datasets'
        },
        views: viewsAdmin({
          templateUrl: "admin/rb-datasets.html",
          controller: "AdminRBDatasetsController"
        })
      })
      .state('root.admin.reservebank.datasets.periods', {
        url: "/:dataset/periods",
        ncyBreadcrumb: {
          label: 'Periods'
        },
        views: viewsAdmin({
          templateUrl: "admin/rb-periods.html",
          controller: "AdminRBPeriodsController"
        })
      })
      .state('root.admin.reservebank.datasets.periods.institutions', {
        url: "/:dataset/:period/institutions",
        ncyBreadcrumb: {
          label: 'Institutions'
        },
        views: viewsAdmin({
          templateUrl: "admin/rb-institutions.html",
          controller: "AdminRBInstitutionsController"
        })
      });

    $urlRouterProvider.otherwise(function ($injector) {
      var $state = $injector.get("$state");
      console.info('ROUTE NOT FOUND...');

      $state.go('root.home');
    });
  }]);

