angular.module('genesisApp')
  .controller('AdminArticleEditController', function ($scope, $rootScope, Article, $stateParams, $location, ARTICLE_TYPES, OptionsService, Upload, $state) {

    $scope.item = $stateParams.id ? (new Article).find($stateParams.id) : (new Article);

    $scope.item.$promise.then(function () {
      if (!$scope.item.type) {
        $scope.item.type = 2;
      }
    });

    $scope.types = {
      options: ARTICLE_TYPES
    };

    $scope.datepickerOptions = {
      locale: {format: 'MMMM D, YYYY'},
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };

    $scope.countries = {
      options: []
    };

    $scope.sources = {
      options: []
    };

    $scope.banks = {
      options: []
    };


    OptionsService.countries().then(function (options) {
      $scope.countries.options = options.data;
    });

    OptionsService.sources().then(function (options) {
      $scope.sources.options = options.data;
    });

    OptionsService.banks().then(function (options) {
      $scope.banks.options = options.data;
    });

    $scope.rules = {};

    $scope.cancel = function () {
      $location.path('/admin/articles');
    };

    console.log("Source id testing:");
    console.log($scope.item.source_id);

    $scope.uploadFile = function (file, errFiles) {
      $scope.f = file;
      $scope.errFile = errFiles && errFiles[0];

      Upload.upload({
        url: '/api/article/upload',
        data: {file: file}
      }).then(function (resp) {
        $scope.item.hyperlink = resp.data.file;
        console.log(resp);
      }, function (resp) {
        console.log('Error status: ' + resp.status);
      }, function (evt) {
        file.progress = parseInt(100.0 * evt.loaded / evt.total);
      });
    };

    $scope.save = function (item) {
      item.save().then(function () {
        $rootScope.addOnlyAlert("success", "Article saved successfully");
        $state.go('root.admin.articles.create', {}, {reload: true});
        //$location.path('/admin/articles');
      }, function (resp) {
        $rootScope.addOnlyAlert("error", "Article could not be saved");

        $scope.rules = resp.data;
      });
    };

  });

