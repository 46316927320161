angular.module('genesisApp')
  .controller('BanksInfoController', function ($scope, $rootScope, $location, ArticleService, $stateParams, Bank, ARTICLE_TYPES, $httpParamSerializer, $http, $state) {
    console.log('BanksInfoController....');
    $scope.bank = $stateParams.bank_id ? (new Bank).find($stateParams.bank_id) : (new Bank);

    $scope.loadData = loadData;
    $scope.loadReport = loadReport;
    $scope.articleTypes = ARTICLE_TYPES;
    $scope.bankArticles = [];

    loadData();

    function loadData () {
    }

    $scope.bank.$promise.then(function () {
      loadReport();
    });


    //$httpParamSerializer(params);
    function loadReport () {
      var uuid = '93fc0514-d2f5-2070-751f-8d0e40d957d6';
      var apiQuery = angular.copy($location.search());
      apiQuery.id = uuid;
      apiQuery.compile = true;
      apiQuery.institution = $scope.bank.code;

      var apiQueryStr = $httpParamSerializer(apiQuery);

      $scope.reportLoading = true;

      $http.get('/api/reports/' + uuid + '?' + apiQueryStr).then(function (resp) {
        $scope.report = resp.data;

        $http.get('/api/reports/date-menus?series=BA900').then(function (resp) {
          $scope.reportLoading = false;
          $scope.reportMenu = resp.data;
        });

      });

    }


  });

