angular.module('genesisApp')
    .controller('AdminRBPeriodsController', ['$scope', '$rootScope', '$stateParams', 'Period', 'FetchXML',
        function ($scope, $rootScope, $stateParams, Period, FetchXML) {
            console.log('AdminPeriodsController...');

            $scope.dataset = $stateParams.dataset;

            $scope.loadData = loadData;
            $scope.list =  null;
            $scope.loadingData = true;

            loadData();

            function loadData() {
                Period.get({'dataset': $scope.dataset}).then(function (response) {
                    $scope.loadingData = false;
                    $scope.list = response.data;
                });
            }

            $scope.isLoading = function (){
                return $scope.loadingData;
            };

            $scope.import = function (period) {
                if (period.status) {
                    return false;
                }

                var params = {
                    'dataset': $scope.dataset,
                    'period': period.date
                };

                period.isImporting = true;

                FetchXML.import(params).then(function (response) {
                    period.isImporting = false;
                    period.status = 'Success';

                    $rootScope.$broadcast('rbJobsAdded', {});
                });
            };
        }]);

