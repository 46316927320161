angular.module('genesisApp')
    .factory('Period', ['$http', function ($http) {
        var Period = {};

        Period.get = function (params) {
            return $http.get('/api/reservebank/periods', {params: params});
        };

        return Period;
    }]);

