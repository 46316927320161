angular.module('genesisApp')
  .controller('StatementsController', function ($scope, $rootScope, $stateParams, Statements, $location, ARTICLE_TYPES) {
    console.log('StatementsController....');

    $scope.query = $location.search();
    $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
    $scope.query.bank_id = parseInt($scope.query.bank_id, 10) || null;
    $scope.query.type = parseInt($scope.query.type, 10) || null;

    $scope.loadData = loadData;
    $scope.updateQuery = updateQuery;
    $scope.search = search;
    $scope.clearSearch = clearSearch;
    $scope.list = new Statements;

    if ($scope.query.article_type) {
      $scope.query.article_type = parseInt($scope.query.article_type);
    }

    loadData();

    function loadData () {
      $scope.loadingData = true;
      $scope.list.get($location.search());
      $scope.list.$promise.then(function () {
        $scope.loadingData = false;
      });
    }

    function search () {
      $scope.query.page = 1;
      $scope.updateQuery();
    }

    function clearSearch () {
      $scope.query = {};
      $scope.search();
    }

    function updateQuery () {
      var query = angular.copy($scope.query);

      if ($scope.query.startDate) {
        query.startDate = moment($scope.query.startDate).format('YYYY-MM-DD');
      }
      if ($scope.query.endDate) {
        query.endDate = moment($scope.query.endDate).format('YYYY-MM-DD');
      }

      console.log('query', query);

      $location.search(query);

      loadData();
    }
  });

