angular.module('genesisApp')
.factory('Links', function (ModelCollection, Link) {
    function Links() {
        this.$model = Link;
        this.configure({url: 'api/links'});
    }

    inherit(Links, ModelCollection);
    return Links;
})
.factory('Link', function (Model) {
    function Link(data) {
        this.hydrate(data);
        this.configure({url: 'api/links/:id'});
    }

    inherit(Link, Model);
    return Link;
});

