angular.module('genesisApp')
  .controller('AdminArticlesController', ['$scope', '$rootScope', 'Articles', '$location', 'OptionsService',
    function ($scope, $rootScope, Articles, $location, OptionsService) {
      $scope.query = $location.search();
      $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
      $scope.loadData = loadData;
      $scope.updateQuery = updateQuery;
      $scope.search = search;
      $scope.clearSearch = clearSearch;
      $scope.destroy = destroy;
      $scope.list = new Articles;

      $scope.sources = {
        options: []
      };

      $scope.countries = {
        options: []
      };

      OptionsService.sources().then(function (options) {
        $scope.sources.options = options.data;
      });

      OptionsService.countries().then(function (options) {
        $scope.countries.options = options.data;
      });

      $scope.datePicker = {
        dates: {
          startDate: null,
          endDate: null
        }
      };


      $scope.fields = [
        {
          label: 'Heading',
          value: 'heading'
        },
        {
          label: 'Hyperlink',
          value: 'hyperling'
        },
        {
          label: 'Body',
          value: 'body'
        }
      ];

      if ($scope.query.startDate) {
        $scope.datePicker.dates = {
          startDate: moment($scope.query.startDate),
          endDate: moment($scope.query.endDate)
        };
      }

      if ($scope.query.article_type) {
        $scope.query.article_type = parseInt($scope.query.article_type);
      }

      loadData();


      function loadData () {
        $scope.loadingData = true;
        $scope.list.get($location.search());
        $scope.list.$promise.then(function () {
          $scope.loadingData = false;
        });
      }

      function search () {
        $scope.query.page = 1;
        $scope.updateQuery();
      }

      function clearSearch () {
        $scope.query = {};
        $scope.search();
      }

      function updateQuery () {
        var query = angular.copy($scope.query);

        if ($scope.datePicker.dates.endDate) {
          query.endDate = moment($scope.datePicker.dates.endDate).format('YYYY-MM-DD');
        }

        if ($scope.datePicker.dates.startDate) {
          query.startDate = moment($scope.datePicker.dates.startDate).format('YYYY-MM-DD');
        }

        if ($scope.query.send_at) {
          query.send_at = moment($scope.query.send_at).format('YYYY-MM-DD');
        }

        $location.search(query);
        loadData();
      }

      function destroy (item) {
        if (confirm("Are you sure you want to delete: " + item.heading + '?')) {
          item.destroy().then(function () {
            $rootScope.addAlert('success', 'Article deleted');
            loadData();
          }, function (data) {
            $rootScope.addAlert('error', 'Error deleting article');
          })
        }
      }

    }]);

