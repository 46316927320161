angular.module('genesisApp')
    .factory('NewsletterService', ['$http', function ($http) {
        var newsletterService = {};

        newsletterService.send = function (params) {
            var action = params.is_test ? 'test' : 'send';
            return $http.post('/api/newsletter/schedule', params);
        };

        return newsletterService;
    }]);

