angular.module('genesisApp')
.constant('LINK_TYPES',
    [
        {
            id: 1,
            name: 'Associations'
        },
        {
            id: 3,
            name: 'Bank related institutions'
        },
        {
            id: 4,
            name: 'Banks'
        },
        {
            id: 5,
            name: 'Central Bank'
        }
    ]
)
.constant('LINK_SUB_TYPES',
    [
        {
            id: 1,
            name: 'Foreign controlled'
        },
        {
            id: 3,
            name: 'Locally controlled'
        },
        {
            id: 4,
            name: 'Mutual Banks'
        },
        {
            id: 5,
            name: 'Registered Branches'
        },
        {
            id: 6,
            name: 'Representative Offices'
        }
    ]
);

