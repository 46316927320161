angular.module('genesisApp')
        .controller('AdminStatementsController', ['$scope', '$rootScope', 'Statements', 'OptionsService', '$location',
            function ($scope, $rootScope, Statements, OptionsService, $location) {

                $scope.query = $location.search();
                $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
                $scope.loadData = loadData;
                $scope.updateQuery = updateQuery;
                $scope.search = search;
                $scope.clearSearch = clearSearch;
                $scope.destroy = destroy;
                $scope.list = new Statements;

                $scope.banks = {
                    options: []
                };

                OptionsService.banks().then(function (options) {
                    $scope.banks.options = options.data;
                });

                loadData();

                function loadData() {
                    $scope.list.get($scope.query);
                    $scope.list.$promise.then(function () {
                    });
                }

                function search() {
                    $scope.query.page = 1;
                    $scope.updateQuery();
                }

                function clearSearch() {
                    $scope.query = {};
                    $scope.search();
                }

                function updateQuery() {
                    $location.search($scope.query);
                    loadData();
                }

                function destroy(item) {
                    if (confirm("Are you sure you want to delete: " + item.title + '?')) {
                        item.destroy().then(function () {
                            $rootScope.addAlert('success', 'Statements deleted');
                            loadData();
                        }, function (data) {
                            $rootScope.addAlert('error', 'Error deleting statement');
                        });
                    }
                }

            }]);    

