angular.module('genesisApp')
  .factory('Subscriptions', function (ModelCollection, Subscription) {
    function Subscriptions () {
      this.$model = Subscription;
      this.configure({url: 'api/subscriptions'});
    }

    inherit(Subscriptions, ModelCollection);
    return Subscriptions;
  })
  .factory('Subscription', function (Model, Users) {
    function Subscription (data) {
      this.hydrate(data);
      this.configure({url: 'api/subscriptions/:id'});
      this.$statusOptions = [
        {value: "trial", label: "Trial"},
        {value: "active", label: "Active"},
        {value: "disabled", label: "Disabled"}
      ];
    }

    inherit(Subscription, Model);


    Subscription.prototype.beforeHydrate = function (data) {
      if (data.users) {
        data.users = new Users(data.users);
      }
    };

    return Subscription;
  });

