angular.module('genesisApp')
  .controller('ModalNewsletterTestController', function ($scope, Users, $rootScope, NewsletterService, $location, $uibModalInstance) {
    $scope.item = $rootScope.newsletter;
    $scope.query = $location.search();
    $scope.query.page = $scope.query.page ? parseInt($scope.query.page, 10) : 1;
    $scope.loadData = loadData;
    $scope.updateQuery = updateQuery;
    $scope.search = search;
    $scope.clearSearch = clearSearch;
    $scope.list = new Users;
    $scope.Users = [];
    $scope.usersIds = [];
    $scope.isSending = false;

    $scope.fields = [
      {
        label: 'Email',
        value: 'email'
      },
      {
        label: 'First Name',
        value: 'first_name'
      },
      {
        label: 'Last Name',
        value: 'last_name'
      },
      {
        label: 'Company',
        value: 'company'
      }
    ];

    loadData();

    function loadData () {
      $scope.query.is_test = true;
      $scope.loadingData = true;
      $scope.list.get($scope.query);
      $scope.list.$promise.then(function (data) {
        $scope.loadingData = false;
      });
    }

    function search () {
      $scope.query.page = 1;
      $scope.updateQuery();
    }

    function clearSearch () {
      $scope.query = {};
      $scope.search();
    }

    function updateQuery () {
      $location.search($scope.query);
      loadData();

      console.log("loading cool data");
    }

    $scope.test = function (userIds) {
      console.log("Sending test newsletters here.");

      $scope.isSending = true;

      var users = [];

      angular.forEach($scope.list, function (item) {
        if (item.$selected) {
          users.push(item.id);
        }
      });

      var data = {
        id: $scope.item.id,
        is_test: true,
        users: users
      };

      NewsletterService.send(data).then(function (response) {
        console.log(response);

        $scope.isSending = false;
        $rootScope.addAlert('success', 'Newsletter scheduled to send');
        $uibModalInstance.dismiss('cancel');

        $rootScope.$broadcast('newsletterScheduled', {});

      });
    };

    $scope.items = [];
    $scope.ok = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

