angular.module('genesisApp')
  .controller('PageController', function ($scope, $http) {

    $scope.cmsData = null;


    $scope.cmsData = null;
    $scope.cmsDataLoading = true;

    $http.get('/api/cms-content').then(function (resp) {
      $scope.cmsData = resp.data;
      $scope.cmsDataLoading = false;
      console.log('$scope.cmsData', $scope.cmsData);
    });


    $scope.aboutPeople = [
      {name: "James Bernstein", title: "Risk Management", email: "jamesb@genesis-analytics.com", image: "/images/jamesb.jpg"},
      {name: "Muhammed Hajat", title: "Transaction Advisory", email: "muhammedh@genesis-analytics.com", image: "/images/muhammedh.jpg"},
      {name: "Richard Ketley", title: "Managing Partner", email: "richardk@genesis-analytics.com", image: "/images/richard.jpg"},
      {name: "Neil Lightfoot", title: "Partner (Applied Behavioural Economics)", email: "neill@genesis-analytics.com", image: "/images/neil.jpg"},
      {name: "Mauro Mela", title: "Manager", email: "maurom@genesis-analytics.com", image: "/images/mauro.jpg"},
      {name: "Bavani Naidoo", title: "Partner", email: "bavanin@genesis-analytics.com", image: "/images/bavani.jpg"},
      {name: "Jason Wang", title: "Senior Associate", email: "jasonw@genesis-analytics.com", image: "/images/jason.jpg"},
      {name: "Jenni van der Merwe", title: "Genesis Financial News &amp; Data", email: "jennivdm@genesis-anlaytics.com", image: "/images/jenni.jpg"},
      {name: "Shezaad Sunderji", title: "Principal (Financial Services | East Africa)", email: "", image: "/images/shezaad_2.jpg"},
      {name: "Tascha Terblanche", title: "Manager (Financial Services | West Africa)", email: "", image: "/images/tascha.jpg"},
      {name: "Tom Magara", title: "Manager (Financial Services | East Africa)", email: "", image: "/images/Tom_FS_28229.jpg"},
    ];
  })
;

