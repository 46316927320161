angular.module('genesisApp')
    .controller('AdminRBInstitutionsController', ['$scope', '$rootScope', '$stateParams', 'Institution', 'FetchXML',
        function ($scope, $rootScope, $stateParams, Institution, FetchXML) {
            console.log('AdminInstitutionsController...');

            $scope.dataset = $stateParams.dataset;
            $scope.period = $stateParams.period;

            $scope.loadData = loadData;
            $scope.list =  null;
            $scope.loadingData = true;

            loadData();

            function loadData() {
                var params = {
                    'dataset': $scope.dataset,
                    'period': $scope.period
                };

                Institution.get(params).then(function (response) {
                    $scope.loadingData = false;
                    $scope.list = response.data;
                });
            }

            $scope.isLoading = function (){
                return $scope.loadingData;
            };

            $scope.import = function (item) {
                if(item.status){
                    return false;
                }

                var params = {
                    'dataset': $scope.dataset,
                    'period': $scope.period,
                    'institution': item.id
                };

                item.isImporting = true;

                console.log("Before data>>>>");
                FetchXML.import(params).then(function (response) {
                    item.isImporting = false;
                    item.status = 'Success';
                    console.log(response);
                    console.log("After data>>>>");

                    $rootScope.$broadcast('rbJobsAdded', {});
                });
            };
        }]);

